import {RejectionReducer} from "../../rejectionHandler";
import * as sprout from "sprout-data";

export const onPasswordRejected: RejectionReducer = {
    reducer: (state: any, error: any) => {
        let errorArray: string[];
        if (typeof error.message === "string") {
            errorArray = [error.message];
        } else {
            errorArray = error.message as string[];
        }
        return sprout.assoc(state,
            ["server", "auth", "errorMessage"], errorArray,
            ["server", "auth", "processing"], false,
        );

    },
    overrideGlobal: true
};
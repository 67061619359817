import {ContainsNonWhiteSpaceCharsRegEx} from "./regexp";
import {Contact} from "lightrail-client/dist/model";

export const defaultDash = "\u2014";
export const copyright = "\u00A9";
export const badgeGreen = "#2cbe4e";
export const badgeYellow = "#ead200";
export const badgeRed = "#EF3D6D";
export const hiddenLetterDot = ".";
export const ellipsis = "…";
export const numeralCurrencyFormat = "$0,0[.]00";

/**
 * Splits a category key/value string into an object
 *
 * @param {string} keyValue - formatted as "key-value"
 * @returns {{key: (any|string), value: (any|string)}}
 */
export const splitDashedKeyValue = (keyValue: string): { key: string, value: string } => {
    const keyValueValues = keyValue.split(/\-(.+)?/) || [];
    return {key: (keyValueValues[0] || ""), value: (keyValueValues[1] || "")};
};

export const csvStringToArray = (csvString: string): string[] => csvString.split(/\s*,\s*/g);

export const looksLikeJson = (value: any): boolean => (typeof value == "string" && (value.charAt(0) == "{" || value.charAt(0) == "["));

/**
 * Generates a string of dots, used to represent the hidden part of a code
 *
 * @param {number} numberOfDots
 * @returns {string} ex: "....."
 */
export const trailingDotString = (numberOfDots: number): string => {
    return new Array(numberOfDots + 1).join(hiddenLetterDot);
};

/**
 * Simplify and Obfuscate an ID(or any string) with DOTS! ie: "card-fa;lskdfjalsdkfjkasdf" - becomes -> "•••df"
 * @param {string}id
 * @param {number}numDots
 * @param {number}numChars
 */
export const prefixIdWithDots = (id: string, numDots?: number, numChars?: number): string => {
    if (!id) {
        return null;
    }

    const dotCount = (!numDots) ? 3 : numDots;
    const charCount = (!numChars) ? 4 : numChars;

    if (id.charAt(0) !== ".") {
        return trailingDotString(dotCount).concat(id.slice(-charCount));
    }

    return id;
};

export const formatLastFour = (lastFour: string, prefixCount?: number): string => {
    let prefix = "****";
    if (prefixCount === 0) {
        return lastFour;
    }
    if (!!prefixCount) {
        prefix = new Array(prefixCount).join("*") + "*";
    }
    return prefix + lastFour;
};

/**
 * Joins an array of strings with a blank space
 *
 * @param {string[]} classNames - ex: ["redText", "bigText"]
 * @returns {string} - ex: "redText bigText"
 */
//@depricated -> Replace usages with `${}`<<<<<<< HEAD
export const classNames = (classNames: string[]): string => {
    return classNames.join(" ");
};

/**
 * Pluralizes a singular description unless value is 1
 * @param {number}value
 * @param {string}singularDescription
 * @returns {string}
 */
export const pluralize = (value: number, singularDescription: string): string => {
    if (value === 1) {
        return singularDescription;
    }

    return singularDescription + "s";
};

/**
 * Tests a string for any non-whitespace characters
 * @param {string}testString
 * @returns {boolean}
 */
export const hasNonWhitespaceCharacters = (testString: string): boolean => {
    return ContainsNonWhiteSpaceCharsRegEx.test(testString);
};

export const removeWhitespace = (p: string): string => p.replace(/ /g, "");

export const capitalizeFirstLetter = (s: string): string => s.charAt(0).toUpperCase() + s.slice(1);

/**
 * Convert camelCase and snake_case keys/strings to Camel Case and Snake Case
 * @param {string} key
 * @returns {string}
 */
export const getReadableKey = (key: string): string => capitalizeFirstLetter(
    key.replace(/[A-Z]/g, match => " " + match)// camelCase
        .replace(/_([A-z0-9]){0,1}/g, (match, p1) => (p1) ? " " + p1.toUpperCase() : " ") //snake_case
        .replace(/ +/g, " ")  // remove any extra space (useful for when snake_Camel happens)
);

/**
 * Remove `lightrail_webapp`from namespaced metadata
 * @param {string} key
 * @returns {string}
 */
export const filterLightrailNamespace = (key: string): string => (
    key.replace(/lightrail_webapp_/, "")
);

/**
 * Truncates text with default "..." or custom string
 * @param {string}text
 * @param {number}totalLength
 * @param {string?}postFix - OPTIONAL custom postfix, default is "..."
 * @returns {string}
 */
export const truncate = (text: string, totalLength: number, postFix?: string): string => {
    if (!text) {
        return "";
    }

    if (text.length > totalLength) {
        if (!postFix && postFix != "") {
            postFix = "...";
        }
        const substrLength = (postFix.length < totalLength) ? totalLength - (postFix.length) : 0;
        return text.substr(0, substrLength).concat(postFix);
    }

    return text;
};

export const textValueReplacer = <T extends { [key: string]: string }>(vars: T): (text: string) => string => {
    return (text: string): string => replaceValuesInText<T>(text, vars);
};

export const replaceValuesInText = <T extends { [key: string]: string }>(text: string, values: T): string => (
    (!!text)
        ? text.replace(/\${\w+}/g, replacePattern => {
            const key = replacePattern.match(/\w+/)[0];
            return (!!values[key]) ? values[key] : "";
        })
        : ""
);

export const getContactName = (contact: Contact): string => {
    if (!!contact.firstName && !!contact.lastName) {
        return (`${contact.firstName} ${contact.lastName}`);
    }

    if (!!contact.firstName) {
        return (`${contact.firstName}`);
    }

    if (!!contact.lastName) {
        return (`${contact.lastName}`);
    }

    return "Undefined Name";
};

export const formatParamsError = (error: string): string => {
    return (!!error) ? error.replace("params.", "") : "";
};

export const generateRandomString = (length: number, charset: string): string => {
    let randomString = "";
    for (let i = 0; i < length; i++) {
        randomString += charset.charAt(Math.floor(Math.random() * charset.length));
    }
    return randomString;
};
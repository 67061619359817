export type SelectedRows = number[] | string[] | string | "all" | "none";

export enum ActionDialogsState {CLOSED, TAGGING, SUMMARY, SUCCESS}

export interface ActionDialogs {
    state: ActionDialogsState;
}

export interface BulkTagState {
    isOpen?: boolean;
    tags?: string[];
}

export interface BulkCardActionState {
    tag?: BulkTagState;
}

export interface BulkActionState {
    cards?: BulkCardActionState;
}

export interface ToggleableColumnTableState {
    enabledColumns?: string[];
    sort?: string;
    selectedRows?: SelectedRows;
    action?: string | number;
}

export interface PasswordFieldUiState {
    value: string;
    visible: boolean;
    blurred?: boolean;
}

export const InfoDialog_GlobalId = "globalInfoDialog";

export interface SessionUIState {
    expiredOpen?: boolean;
}

export interface InfoDialogState {
    id?: string;
    isOpen?: boolean;
    title?: string;
    message?: string;
    learnMoreLabel?: string;
    learnMoreLink?: string;
}

export interface ComponentUiState {
    actionDialogs?: { [id: string]: ActionDialogs };
    bulk?: BulkActionState;
    infoDialogs?: { [id: string]: InfoDialogState };
    passwordFields?: { [id: string]: PasswordFieldUiState };
    session?: SessionUIState;
    testModeBanner: { open: boolean };
    toggleableColumnTables?: { [id: string]: ToggleableColumnTableState };
}

export const InitialComponentUiState: ComponentUiState = {
    actionDialogs: {},
    bulk: {
        cards: {
            tag: {
                isOpen: false,
                tags: []
            }
        }
    },
    infoDialogs: {},
    passwordFields: {},
    session: {
        expiredOpen: false
    },
    testModeBanner: {open: false},
    toggleableColumnTables: {},
};
import { ActionCreator, Reducer } from "../../Action";
import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";

export interface ActionParams {
    status?: number;
    code?: string;
    message?: string;
    ignoreError?: boolean;
}

export interface ActionCreatorArgs {
    status?: number;
    code?: string;
    message?: string;
    ignoreError?: boolean;
}
export const type = "setRestError";

export const actionCreator: ActionCreator<ActionCreatorArgs, ActionParams> = (args) => ({
    type,
    payload: args
});

export const reducer: Reducer<ActionParams> = (state, action) => {
    return sprout.assoc(state,
        ["server", "restError", "status"], action.payload?.status || 0,
        ["server", "restError", "code"], action.payload?.code || "",
        ["server", "restError", "message"], action.payload?.message || "",
        ["server", "restError", "ignoreError"], action.payload?.ignoreError
    );
};

export const reducerMap = promisePattern.reducerMap(type, reducer);
export interface AuthServerStateErrorMessage extends Array<string> {
}

export interface AuthState {
    processing?: boolean;
    successMessage?: string;
    resetPasswordMessage?: string;
    updatePasswordMessage?: string;
    errorMessage?: AuthServerStateErrorMessage;
    resetToken?: string;
}

export const InitialAuthServerState = {};

export interface RegisterState {
    processing?: boolean;
    emailSent?: boolean;
    error?: AuthServerStateErrorMessage;
}

export const InitialRegisterServerState = {};
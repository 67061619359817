import * as promisePattern from "../../promisePattern";
import * as auth from "../../../communication/auth";
import {LoginResponse} from "../../../communication/dtos/auth/Login";
import * as sprout from "sprout-data";
import {rejectionHandler} from "../../rejectionHandler";
import {setSentryUserContext} from "../../../util/sentry";
import {onPasswordRejected} from "./passwordRejectionHandler";
import {initialState} from "../../../state/State";
import {UserModes} from "../../../communication/dtos/auth/PingResponse";
import {redirectOnMessageCode} from "../../../util/routes/redirectOnMessageCode";

export const type = "auth";

export const actionCreator = promisePattern.actionCreator(type, auth.login);

const onFulfilled: promisePattern.PromiseFulfilledHandler<LoginResponse> =
    (state, payload) => {
        const testing = payload?.user.mode === UserModes.TEST;
        if (payload?.user.email) {
            setSentryUserContext(payload?.user.email);
        }

        const accountMessage = {
            message: payload?.message,
            messageCode: payload?.messageCode
        };

        const {id, email, mode, additionalAuthenticationRequired} = payload?.user;
        const user = sprout.assoc(state.server.user,
            ["id"], id,
            ["email"], email,
            ["mode"], mode,
            ["additionalAuthenticationRequired"], additionalAuthenticationRequired,
            ["loggedIn"], true,
            ["testing"], testing
        );

        return sprout.assoc(initialState,
            ["server", "auth", "processing"], false,
            ["server", "user"], user,
            ["server", "MFA", "mfa"], payload?.user.mfa,
            ["server", "account", "authMessage"], accountMessage,
            ["ui", "history"], redirectOnMessageCode(state.ui.history, accountMessage.messageCode)
        );
    };

const onRejected: promisePattern.PromiseRejectedHandler = rejectionHandler([onPasswordRejected]);

const onPending: promisePattern.PromisePendingHandler =
    (state) => {
        return ({
            ...state,
            server: {
                ...state.server,
                auth: {
                    ...state.server.auth,
                    processing: true
                }
            }
        });
    };

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);
/// <reference path="../remark-parse.d.ts" />

import * as React from "react";
import * as remarkParse from "remark-parse";
import {CodeSnippet} from "../CodeSnippet";
import {Tabs} from "antd";

type MergedCodeNode = remarkParse.Node & {
    type: "mergedCode";
    value: remarkParse.CodeNode[];
};

export interface Props {
    value: remarkParse.CodeNode[];
}

/**
 * React element to render type=mergedCode nodes.
 */
export class TabbedCodeSnippets extends React.PureComponent<Props, {}> {
    constructor(props: Props) {
        super(props);
    }

    /**
     * An astPlugin that must be run on the AST before it is rendered for this
     * renderer to function.  Merges adjacent type=code nodes into a single
     * type=mergedCode node.
     */
    static astPlugin(tree: remarkParse.RootNode): remarkParse.RootNode {
        for (let childIx = 0; childIx < tree.children.length; childIx++) {
            if (tree.children[childIx].type === "code") {
                if (childIx > 0 && (tree.children[childIx - 1] as MergedCodeNode).type === "mergedCode") {
                    (tree.children[childIx - 1] as MergedCodeNode).value.push((tree.children.splice(childIx--, 1)[0]) as remarkParse.CodeNode);
                } else {
                    tree.children[childIx] = {
                        type: "mergedCode",
                        value: [tree.children[childIx] as remarkParse.CodeNode],
                        position: tree.children[childIx].position
                    } as MergedCodeNode;
                }
            }
        }

        return tree;
    }

    render(): JSX.Element {
        return (
            <div>
                {
                    (this.props.value && this.props.value.length) &&
                    (this.props.value.length > 1)
                        ?
                        <Tabs animated={false}>
                            {this.props.value.map((codeNode, index) => (
                                <Tabs.TabPane
                                    key={index.toString()}
                                    className="docs-code-snippet-tab"
                                    tab={
                                        <span className="docs-code-snippet-tab-label">
                                            {codeNode.lang || "code"}
                                         </span>
                                    }
                                >
                                    <CodeSnippet code={codeNode.value} language={codeNode.lang}/>
                                </Tabs.TabPane>)
                            )}
                        </Tabs>
                        : <CodeSnippet
                            code={this.props.value[0].value}
                            language={this.props.value[0].lang}
                        />
                }
            </div>
        );
    }
}

import { BaseServerState } from "./BaseServerState";

export interface SendGiftCardState extends BaseServerState {
    success?: boolean;
}

export interface TurnkeyStripeState extends BaseServerState {
    connected?: boolean;
}

export interface TurnkeyServerState {
    stripe?: TurnkeyStripeState;
    sendGiftCard?: SendGiftCardState;
}

export const InitialTurnkeyServerState = {
    stripe: {
        connected: false
    },
    sendGiftCard: {}
};
import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {updateAccountUser} from "../../../communication/account";
import {State} from "../../../state/State";
import {rejectionHandler, RejectionReducer} from "../../rejectionHandler";
import {UpdateUserResponse} from "../../../communication/dtos/account/UpdateUser";
import {User} from "../../../communication/dtos/account/User";

export const type = "updateAccountUser";

export const actionCreator = promisePattern.actionCreator(type, updateAccountUser);

const onFulfilled: promisePattern.PromiseFulfilledHandler<UpdateUserResponse> = (state, payload) => {
    const updatedMembers = state.server.account.users.list.map((p: User) => (p.userId === payload?.userId) ? payload : p);
    return sprout.assoc(state,
        ["server", "account", "users", "list"], updatedMembers,
        ["server", "account", "users", "fetching"], false,
        ["server", "account", "users", "error"], null
    );
};

const onCreateTeamRejected: RejectionReducer = {
    reducer: (state, error) => sprout.deepMerge<State>(state, {
        server: {
            account: {
                users: {
                    processing: false,
                    error: error.message
                }
            }
        }
    })
};

const onRejected = rejectionHandler([onCreateTeamRejected]);

const onPending: promisePattern.PromisePendingHandler =
    (state) => sprout.deepMerge<State>(state, {
        server: {
            account: {
                users: {
                    processing: true,
                    error: null
                }
            }
        }
    });

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);
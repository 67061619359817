// Ftue => First Time User Experience

export interface FtueDropinSetupUiState {
    dialogOpen: boolean;
    active: boolean;
}

export interface FtueUiState {
    welcomeDialogOpen?: boolean;
    dropinSetup: FtueDropinSetupUiState;
}

export const InitialFtueUiState = {
    welcomeDialogOpen: false,
    dropinSetup: {
        dialogOpen: false,
        active: false
    }
};
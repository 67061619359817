import * as React from "react";
import {State} from "../../src/state/State";
import {ScreenSize} from "../../src/state/uistate/LayoutUiState";
import {ActionCreatorArgs as SetUIStateArgs} from "../../src/actions/uiAction";
import {onMatchMedia} from "../../src/util/viewportLayoutUtil";
import {UiActionPaths} from "../../src/state/uistate/UiActionPaths";
import {ActionResponse} from "../../src/actions/promisePattern";
import {PingResponse} from "../../src/communication/dtos/auth/PingResponse";
import {MainLayout} from "./MainLayout";
// ONLY NEED TO IMPORT THIS HERE - These are the Globals
import "../styles/theme.less";

require("../styles/app.scss");

export interface Props {
    state: State;

    ping: () => Promise<ActionResponse<PingResponse>>;
    setUIState: (params: SetUIStateArgs) => void;
}

/**
 * The top-level container of the application.  In development this can be hot-loaded.
 */
export class DocsApp extends React.Component<Props, {}> {
    constructor(props: Props) {
        super(props);

        this.onScreenSizeChange = this.onScreenSizeChange.bind(this);
        this.scrollToTop = this.scrollToTop.bind(this);
    }

    shouldComponentUpdate(): boolean {
        return false;
    }

    componentDidMount(): void {
        this.setupMQLs();
        this.props.ping();
    }

    setupMQLs(): void {
        //NOTE: These values match the media queries used in _breakpoints.scss
        onMatchMedia("(max-width: 480px)", this.onScreenSizeChange.bind(this, ScreenSize.SMALL));
        onMatchMedia("(min-width: 480px) and (max-width: 840px)", this.onScreenSizeChange.bind(this, ScreenSize.MEDIUM));
        onMatchMedia("(min-width: 840px)", this.onScreenSizeChange.bind(this, ScreenSize.LARGE));
    }

    onScreenSizeChange(screenSize: ScreenSize): void {
        this.props.setUIState({uiStatePath: UiActionPaths.LAYOUT_SCREEN_SIZE, value: screenSize});
    }

    scrollToTop(): void {
        window.scrollTo(0, 0);
    }

    render(): JSX.Element {
        return (
            <MainLayout/>
        );
    }
}

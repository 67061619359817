import {prefixIdWithDots} from "../stringUtils";

export const Paths = {
    ROOT: "/",
    DASHBOARD: "/",

    //Other Routes
    MAINTENANCE_ROOT: "/maintenance",

    //Auth Routes
    AUTH_ROOT: "/auth",
    LOGIN_ROOT: "/auth/login",
    LOGIN_SUCCESS: "/auth/login/success",
    MULTI_FA: "/auth/login/multifactor",
    REGISTER_ROOT: "/auth/register",
    FORGOT_PASSWORD: "/auth/forgotpassword",
    RESET_PASSWORD: "/resetpassword",
    AUTH_NEXT_STEP: "/auth/accountNextStep",

    //App Routes (use Main.tsx)
    ACCOUNT_ROOT: "/account",
    ACCOUNT_SECTION_KEY: "/account/:subsection",
    ACCOUNT_PROFILE: "/account/profile",
    ACCOUNT_API: "/account/api",
    ACCOUNT_SECURITY: "/account/security",
    ACCOUNT_BILLING: "/account/billing",
    ACCOUNT_TEAM: "/account/team",
    ACCOUNT_TEAM_CREATION: "/account/team/create",
    ACCOUNT_CURRENCY: "/account/currency",
    ACCOUNT_WEBHOOK: "/account/webhook",
    ACCOUNT_WEBHOOK_DETAIL: "/account/webhook/details/:webhookId",
    CHANGE_EMAIL_COMPLETED: "/changeEmailComplete",

    ACCOUNTS_SEARCH: "/accounts",
    ACCOUNTS_DETAIL: "/accounts/details/:cardId",

    PROGRAM_LIST: "/programs",
    PROGRAM_CREATION: "/programs/create",
    PROGRAM_DETAIL: "/programs/details/:programId",

    CONTACT_LIST: "/contacts",
    CONTACT_DETAIL: "/contacts/details/:contactId",

    TRANSACTIONS_LIST: "/transactions",
    TRANSACTION_DETAIL: "/transactions/details/:transactionId",

    VALUE_LIST: "/values",
    VALUE_DETAIL: "/values/details/:valueId",

    REPORTS_ROOT: "/reports",

    RELOAD: "/reload"
};

export const PageTitles: { [key: string]: string } = {
    [Paths.DASHBOARD]: "Dashboard",

    [Paths.LOGIN_ROOT]: "Login",
    [Paths.MULTI_FA]: "Login Multifactor Auth",
    [Paths.FORGOT_PASSWORD]: "Forgot Password",
    [Paths.RESET_PASSWORD]: "Reset Password",
    [Paths.AUTH_NEXT_STEP]: "Additional Authentication",

    //App Routes (use Main.tsx)
    [Paths.ACCOUNT_SECTION_KEY]: "Account :subsection",

    [Paths.ACCOUNTS_DETAIL]: "Account :cardId",

    [Paths.PROGRAM_LIST]: "Programs",
    [Paths.PROGRAM_CREATION]: "Create Program",

    [Paths.CONTACT_DETAIL]: "Contact :contactId",
};

export const AuthorizationPaths = [Paths.AUTH_ROOT, Paths.LOGIN_ROOT, Paths.LOGIN_SUCCESS, Paths.MULTI_FA, Paths.REGISTER_ROOT, Paths.FORGOT_PASSWORD, Paths.RESET_PASSWORD, Paths.AUTH_NEXT_STEP];

export const ExternalPaths: any = {
    GIFTBIT_STATIC_ROOT: "https://www.giftbit.com/",

    LIGHTRAIL_STATIC_ROOT: "https://www.lightrail.com/",
    LIGHTRAIL_STATIC_FORM: "https://www.lightrail.com/#gform",
    LIGHTRAIL_CONTACT_SALES: "https://www.lightrail.com/contact/sales/",
    LIGHTRAIL_API_DOCS: "https://www.lightrail.com/docs/",
    LIGHTRAIL_DROP_IN_DOCS: "https://www.lightrail.com/docs/#drop-in-gift-cards/drop-in-gift-cards"
};

////////////////////////////////////////
/// [ ROUTE UTILS]
export const createPathTitle = (path: string, seperator?: string): string => path.split("/").map(word => word.charAt(0).toUpperCase() + word.substr(1)).join(!!seperator ? seperator : " ");

export const getPageTitle = (path: string): string => {
    let title: string;
    Object.keys(PageTitles).some(key => {
        if (doesLocationMatchRoute(path, key)) {
            title = PageTitles[key];

            const pathParams = getLocationParamsFromRoute(key, path);
            let value: string;
            Object.keys(pathParams).forEach(param => {
                value = param.toLowerCase().indexOf("id") ? prefixIdWithDots(pathParams[param]) : pathParams[param];
                title = title.replace(":" + param, value);
            });

            return true;
        }

        return false;
    });

    if (!title) {
        title = createPathTitle(path);
    }

    return `${title} | Lightrail`;
};

//Loose Version of react-routers thing, only replaces the matches, doesn't error if it's missing something
export const formatRoute = (route: string, params: { [key: string]: string }): string => {
    const keys = route.split("/");

    keys.forEach((key: string, i: number) => {
        if (key.charAt(0) == ":" && key.substring(1) in params)  {
            // https://github.com/ReactTraining/history/issues/505 (tried double encoding and it didn't work)
            keys[i] = encodeURIComponent(params[key.substring(1)]?.replace(/%/g, "<[lRPc]>"));
        }
    });

    return keys.join("/");
};

export const getLocationParamsFromRoute = (route: string, location: string): { [key: string]: string } => {
    const keyValues: { [key: string]: string } = {};

    const locationValues = location.split("/");
    const keys = route.split("/");
    keys.forEach((key: string, i: number) => {
        if (key.charAt(0) == ":") {
            keyValues[key.substring(1)] = locationValues[i];
        }
    });

    return keyValues;
};

export const decodeUrlMatchParam = (param: string): string => {
    // https://github.com/ReactTraining/history/issues/505 (tried double encoding and it didn't work)
    // Currently % values used in ids will break navigation, need to replace % signs with something unlikely to be used in an id
    return decodeURIComponent(param.replace(/<\[lRPc]>/g, "%25"));
};

export const doesLocationMatchRoute = (location: string, route: string): boolean => {
    if (route.indexOf(":") === -1) {
        return (location == route);
    } else {
        const staticRoot = route.split(":")[0];
        const sameStaticRoots = (location.indexOf(staticRoot) !== -1);
        const equalParts = (location.split("/").length == route.split("/").length);

        return (sameStaticRoots && equalParts);
    }
};

export const isValidPath = (path: string): boolean => {
    return Object.keys(Paths).some(key => {
        return doesLocationMatchRoute(path, (Paths as any)[key]);
    });
};

export const Navigate = {
    /**
     * Set the dynamic properties of a path
     *
     * @param {string} path
     * @param {array} params - Optional params for dynamic links ie: Navigate.to("recipients/list/details/:listId", 3241)
     * @returns {string}
     */
    setPathParams: (path: string, ...params: any[]): string => {
        let i = 0;
        let value: string;

        return path.replace(/:[a-z0-9]+/gi, () => {
            if (params.length > i) {
                value = params[i];
                i++;
                return value;
            } else {
                //console.error("App Paths.push for:" + path + " requires more than " + params.length + " Parameter(s)");
                return "";
            }
        });
    }
};
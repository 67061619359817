export enum ENVIRONMENTS {UNKNOWN, LOCALHOST, DEV, PRODUCTION, STAGING}

/**
 * Get the environment the app is running in based on the url
 *
 * @returns {ENVIRONMENTS}
 */
export const getEnvironment = (host?: string): number => {
    if (!host) {
        host = (window && window.location) ? window.location.hostname : "unknown";
    }

    if (RegExp(/localhost/).test(host)) {
        return ENVIRONMENTS.LOCALHOST;
    } else if (RegExp(/lightraildev\.(?:[a-z]{2,13})/).test(host)) {
        return ENVIRONMENTS.DEV;
    } else if (RegExp(/lightrailstaging\.(?:[a-z]{2,13})/).test(host)) {
        return ENVIRONMENTS.STAGING;
    } else if (RegExp(/lightrail\.(?:[a-z]{2,13})/).test(host)) {
        return ENVIRONMENTS.PRODUCTION;
    } else {
        return ENVIRONMENTS.UNKNOWN;
    }
};

/**
 * @returns {boolean} - Are we running from our production url?
 */
export const isProductionEnvironment = (host?: string): boolean => {
    return (getEnvironment(host) === ENVIRONMENTS.PRODUCTION);
};

export const getEnvironmentLabel = (host?: string): string => {
    const envEnumValue = host ? getEnvironment(host) : getEnvironment();

    const envLabels = [
        "Unknown",
        "Localhost",
        "Development",
        "Production",
        "Staging"
    ];

    return envLabels[envEnumValue];
};
import * as restAccess from "./utils/restAccess";
import {fetchJson} from "./utils/restAccess";
import {GetAccountResponse} from "./dtos/account/GetAccount";
import {GetInvitedMembersRequest} from "./dtos/account/GetInvitedMembers";
import {GetUsersResponse} from "./dtos/account/GetUsers";
import {CreateUserRequest, CreateUserResponse} from "./dtos/account/CreateUser";
import {DeleteActiveUserResponse, DeleteUserRequest} from "./dtos/account/DeleteActiveUser";
import {DeleteInvitedUserRequest, DeleteInvitedUserResponse} from "./dtos/account/DeleteInvitedUser";
import {GetUserRequest, GetUserResponse} from "./dtos/account/GetUser";
import {UpdateUserRequest, UpdateUserResponse} from "./dtos/account/UpdateUser";
import {ResendMemberInviteRequest, ResendMemberInviteResponse} from "./dtos/account/ResendMemberInvite";
import {CreateAccountRequest, CreateAccountResponse} from "./dtos/account/CreateAccount";
import {EditAccountRequest, EditAccountResponse} from "./dtos/account/EditAccount";
import {ListAccountsResponse} from "./dtos/account/ListAccounts";
import {SwitchAccountsRequest, SwitchAccountsResponse} from "./dtos/account/SwitchAccount";
import {cleanNulls} from "../util/communicationUtils";

const accountPrefix = "/v2/account";

//////// INVITE MEMBERS /////////
/////////////////////////////////
// Create Invited user
export const createAccountUser = (values: CreateUserRequest): Promise<CreateUserResponse> =>
    restAccess.fetchJson(`${accountPrefix}/invitations`, {}, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(cleanNulls(values))
    });

export const resendAccountUserInvite = (values: ResendMemberInviteRequest): Promise<ResendMemberInviteResponse> =>
    restAccess.fetchJson(`${accountPrefix}/invitations`, {}, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({email: values.email})
    });

// users Get Invited
export const getAccountInvites = (): Promise<GetInvitedMembersRequest> => restAccess.fetchJson(`${accountPrefix}/invitations`);

// Delete Invited user
export const deleteInvitedAccountUser = (params: DeleteInvitedUserRequest): Promise<DeleteInvitedUserResponse> =>
    restAccess.fetchJson(`${accountPrefix}/invitations/${params.userId}`, {}, {
        method: "DELETE",
    });

//////// ACTIVE MEMBERS ////////
/////////////////////////////////
// Account Get
export const getAccountUser = (params: GetUserRequest): Promise<GetUserResponse> => restAccess.fetchJson(`${accountPrefix}/users/${params.userId}`);

// Get Active Users
export const getAccountUsers = (): Promise<GetUsersResponse> => restAccess.fetchJson(`${accountPrefix}/users`);
export const updateAccountUser = (params: UpdateUserRequest): Promise<UpdateUserResponse> =>
    restAccess.fetchJson(`${accountPrefix}/users/${params.userId}`, {}, {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({roles: params.roles})
    });

// Delete Active User
export const deleteActiveAccountUser = (params: DeleteUserRequest): Promise<DeleteActiveUserResponse> =>
    restAccess.fetchJson(`${accountPrefix}/users/${params.userId}`, {}, {
        method: "DELETE",
    });

//////////////////////////
//// ACCOUNT ACTIONS /////
//////////////////////////

export const AccountEndpoints = {
    Details: "/v2/account",
    Create: "/v2/account",
    Update: "/v2/account",
    List: "/v2/user/accounts",
    Switch: "/v2/account/switch"
};

export const getAccountDetails = (): Promise<GetAccountResponse> =>
    fetchJson(AccountEndpoints.Details, {}, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

export const createAccount = (requestParams: CreateAccountRequest): Promise<CreateAccountResponse> =>
    fetchJson(AccountEndpoints.Create, {}, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(cleanNulls(requestParams))
    });

export const editAccount = (requestParams: EditAccountRequest): Promise<EditAccountResponse> =>
    fetchJson(AccountEndpoints.Update, {}, {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(cleanNulls(requestParams))
    });

export const listAccounts = (): Promise<ListAccountsResponse> =>
    fetchJson(AccountEndpoints.List, {}, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        },
    });

export const switchAccounts = (requestParams: SwitchAccountsRequest): Promise<SwitchAccountsResponse> =>
    fetchJson(AccountEndpoints.Switch, {}, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(cleanNulls(requestParams))
    });
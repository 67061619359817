import {combineReducers as reduxBuiltInCombineReducers} from "redux";
import * as deepFreezer from "deep-freeze";
import {Action, Reducer} from "./Action";
import {State} from "../state/State";
import {logSentryException} from "../util/sentry";

export type ReducersReducer = (reducers: Reducer<any>[]) => Reducer<any>;

export type ReducerMap = {[key: string]: Reducer<any>};
export type ReducerMapReducer = (reducers: ReducerMap) => Reducer<any>;

export type ReducersMap = {[key: string]: Reducer<any>[]};
export type ReducersMapReducer = (reducers: ReducersMap) => Reducer<any>;

export const byBruteForce: ReducersReducer =
    (reducers: Reducer<any>[]): Reducer<any> =>
        (state: State, action: Action<any>): State => reducers.reduce((prev, curr) => curr(prev, action), state);

export const byType: ReducerMapReducer =
    (reducers: ReducerMap): Reducer<any> => {
        return (state: State, action: Action<any>): State => {
            if (action.type in reducers) {
                try {
                    /*
                        Attempting to fire ping but ping_PENDING or _FULFILLED needs to be fired.
                        Find out where these actions are fired from and see what's going on there.
                     */
                    return reducers[action.type](state, action);
                } catch (error) {
                    // ui reducers will log twice. they arent in promises, but are ran through here. nothing special on ui reducer to differentiate them
                    // coming through twice shouldnt actually make a difference though just looks sort of like the person caused the error x2 as often.
                    error.message += `\nreducer: ${action.type}`;
                    logSentryException(error);
                    throw error;
                }
            }
            return state;
        };
    };

export const manyByType: ReducersMapReducer =
    (reducers: ReducersMap): Reducer<any> =>
        (state: State, action: Action<any>): State => {
            if (action.type in reducers) {
                return reducers[action.type].reduce((prev, curr) => curr(prev, action), state);
            }
            return state;
        };

export const byKey: ReducerMapReducer = reduxBuiltInCombineReducers;

export const setDefaultState =
    <T>(defaultState: State, reducer: Reducer<T>): Reducer<T> =>
        (state: State, action: Action<T>) =>
            reducer(state || defaultState, action);

export const deepFreeze =
    <T>(reducer: Reducer<T>): Reducer<T> =>
        (state: State, action: Action<T>) => {
            if (!Object.isFrozen(state)) {
                deepFreezer(state);
            }
            return reducer(state, action);
        };

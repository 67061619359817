import {Currency} from "lightrail-client/dist/model/Currency";
import {BaseServerState} from "./BaseServerState";

export interface CurrencyServerState extends BaseServerState {
    list: Currency[];
}

export const InitialCurrencyServerState: CurrencyServerState = {
    list: [],
    fetching: false,
    fetchCount: 0
};
import * as React from "react";
import {Heading, HeadingsNav} from "./HeadingsNav";
import {LogoWordMark} from "../../../src/components/ui/brand/LogoWordMark";

export interface Props {
    headings: Heading[];
}

export class Sidebar extends React.PureComponent<Props, {}> {
    render(): JSX.Element {
        return (
            <div className="sideBar">
                <div className="inner-container">
                    <div className="logo-header">
                        <LogoWordMark height="42"/>
                        <h4 style={{marginTop: 4}} className="headline fontColorSecondary"> Docs
                        </h4>
                    </div>
                    <HeadingsNav headings={this.props.headings}/>
                </div>
            </div>
        );
    }
}
export interface ApiKeysUiState {
    createKeyOpen?: boolean;
    viewNewKeyOpen?: boolean;
}

export interface UserUiState {
    apiKeys: ApiKeysUiState;
}

export const InitialUserUiState = {
    apiKeys: {
        createKeyOpen: false,
        viewNewKeyOpen: false
    }
};
import * as sprout from "sprout-data";
import {ActionPack, Reducer} from "../../src/actions/Action";
import * as combineReducers from "../../src/actions/combineReducers";
import {ReducerMap} from "../../src/actions/combineReducers";
//Notifications
import * as authLogin from "../../src/actions/auth/server/login";
import * as updatePassword from "../../src/actions/auth/server/updatePassword";
import * as forgotPassword from "../../src/actions/auth/server/forgotPassword";
import * as resetPassword from "../../src/actions/auth/server/resetPassword";
import * as setResetPasswordToken from "../../src/actions/auth/server/setResetPasswordToken";
import * as resetAuthErrorAndSuccessStates from "../../src/actions/auth/server/resetAuthErrorAndSuccessStates";
import * as logout from "../../src/actions/auth/server/logout";
import * as register from "../../src/actions/auth/server/register";
import * as ping from "../../src/actions/auth/server/ping";
import * as setCreateTokenState from "../../src/actions/account/server/createToken";
import * as addCreditCard from "../../src/actions/account/server/addCreditCard";
import * as getCreditCard from "../../src/actions/account/server/getCreditCard";
import * as deleteCreditCard from "../../src/actions/account/server/deleteCreditCard";
import * as setRestErrorAlertOpen from "../../src/actions/errors/ui/setRestErrorAlertOpen";
import * as setRestError from "../../src/actions/errors/server/setRestError";
import * as getLongLivedToken from "../../src/actions/account/server/getLongLivedToken";
//user/apikeys
import * as getApiKeys from "../../src/actions/user/server/getApiKeys";
import * as getApiKey from "../../src/actions/user/server/getApiKey";
import * as deleteApiKey from "../../src/actions/user/server/deleteApiKey";
import * as createApiKey from "../../src/actions/user/server/createApiKey";
//Search packs
import * as genericUIAction from "../../src/actions/uiAction";
//Teams
import * as createTeamMember from "../../src/actions/account/server/createAccountUser";
import * as deleteActiveMember from "../../src/actions/account/server/deleteActiveAccountUser";
import * as deleteInvitedMember from "../../src/actions/account/server/deleteInvitedAccountUser";
import * as getTeamInvites from "../../src/actions/account/server/getAccountInvites";
import * as getTeamMember from "../../src/actions/account/server/getAccountUser";
import * as getTeamMembers from "../../src/actions/account/server/getAccountUsers";
import * as updateTeamMember from "../../src/actions/account/server/updateAccountUser";
import * as resendTeamMemberInvite from "../../src/actions/account/server/resendAccountUserInvite";
//Search ui
//Transactions
// Components
//Storage
import * as getStorage from "../../src/actions/kvStorage/server/getStorage";
import * as getStorageValue from "../../src/actions/kvStorage/server/getStorageValue";
import * as updateStorageValue from "../../src/actions/kvStorage/server/updateStorageValue";
import * as deleteStorageValue from "../../src/actions/kvStorage/server/deleteStorageValue";
// ActionPack imports
import {docsInitialState} from "../state/DocsState";

/**
 * Lists of ActionPacks to be combined. Combined array actionPacks is only exported for unit testing.
 */
const userServerActions: ActionPack[] = [getApiKeys, getApiKey, createApiKey, deleteApiKey];
const loginActions: ActionPack[] = [authLogin, register, ping, updatePassword, forgotPassword, resetPassword, setResetPasswordToken, getLongLivedToken, resetAuthErrorAndSuccessStates, logout];
const accountServerActions: ActionPack[] = [addCreditCard, getCreditCard, deleteCreditCard, setCreateTokenState];

const errors: ActionPack[] = [setRestError, setRestErrorAlertOpen];

const teamServerActions: ActionPack[] = [getTeamMembers, updateTeamMember, getTeamMember, getTeamInvites, createTeamMember, deleteActiveMember, deleteInvitedMember, resendTeamMemberInvite];
const storageActions: ActionPack[] = [getStorage, getStorageValue, updateStorageValue, deleteStorageValue];

export const actionPacks: ActionPack[] = [
    ...loginActions,
    ...accountServerActions,
    ...errors,
    ...userServerActions,
    ...teamServerActions,
    ...storageActions,
    genericUIAction
];

/**
 * A list of ReducerMaps compiled from the actionPacks.  Only exported for unit testing.
 */
export const reducerMaps: ReducerMap[] = [
    ...actionPacks
        .filter(actionPack => !!actionPack.type && !!actionPack.reducer)
        .map(actionPack => ({[actionPack.type]: actionPack.reducer} as ReducerMap)),
    ...actionPacks
        .filter(actionPack => !!actionPack.reducerMap)
        .map(actionPack => actionPack.reducerMap)
];
const mergedReducerMap = sprout.merge<ReducerMap>({}, ...reducerMaps);

const mergedReducer = process.env["DEEP_FREEZE"] == "true"
    ? combineReducers.deepFreeze(combineReducers.byType(mergedReducerMap))
    : combineReducers.byType(mergedReducerMap);
export const reducer: Reducer<any> = combineReducers.setDefaultState(docsInitialState, combineReducers.byBruteForce([mergedReducer, combineReducers.byKey({
    ui: (s: any): any => s || {},
    server: (s: any): any => s || {}
})]));
//export const reducer: Reducer<any> = combineReducers.setDefaultState(initialState, mergedReducer);

import {MockHandler} from "./MockHandler";
import {Mock} from "./Mock";
import {QueryParamObject} from "../QueryParamObject";
import {RestError, RestErrorJson} from "../errors/RestError";
import * as urlUtils from "../urlUtils";

const testMethod = (requestMethod: string, handler: MockHandler): boolean => {
    if (!handler.method || typeof handler.method === "string") {
        return requestMethod === ((handler.method && (handler.method as string).toUpperCase()) || "GET");
    } else if (handler.method instanceof RegExp) {
        return (handler.method as RegExp).test(requestMethod);
    }
    throw new Error("Unknown handler method type.");
};

const testPath = (requestPath: string, handler: MockHandler): boolean => {
    if (typeof handler.path === "string") {
        return handler.path === requestPath;
    } else if (handler.path instanceof RegExp) {
        return (handler.path as RegExp).test(requestPath);
    }
    throw new Error("Unknown handler path type.");
};

const testQueryParams = (requestParams: QueryParamObject, handler: MockHandler): boolean => {
    return Object.keys(handler.params || {})
        .every(handlerParamKey => {
            const optional = !!handlerParamKey.length && handlerParamKey[handlerParamKey.length - 1] === "?";
            const requestParamKey = optional ? handlerParamKey.substring(0, handlerParamKey.length - 1) : handlerParamKey;

            if (!(requestParamKey in requestParams)) {
                return optional;
            }

            const requestParamValue = requestParams[requestParamKey];
            const paramTest = handler.params[handlerParamKey];
            const paramTestType = typeof paramTest;
            if (paramTestType === "string" || paramTestType === "number") {
                return paramTest === requestParamValue;
            } else if (paramTest instanceof RegExp) {
                return (paramTest as RegExp).test(requestParamValue.toString());
            }
            throw new Error(`Unknown handler param '${handlerParamKey}' type.`);
        });
};

/**
 * Apply the mock configuration to the request parameters and return the mock response, if any.
 * @param mock the mock settings
 * @param fullPath the request path
 * @param init optional RequestInit
 * @returns a Promise of the Response, or null if no mock applies
 */
export const processMock = (mock: Mock, fullPath: string, init: RequestInit = {}): Promise<Response> => {
    if (!mock) {
        return null;
    }

    const queryParams = urlUtils.getQueryParams(fullPath);
    const path = fullPath.split("?")[0];

    if (mock.handlers) {
        const requestMethod = (init.method && init.method.toUpperCase()) || "GET";
        for (let i = 0; i < mock.handlers.length; i++) {
            const handler = mock.handlers[i];

            const isTestMethod = testMethod(requestMethod, handler);
            const isTestPath = testPath(path, handler);
            const isTestParams = testQueryParams(queryParams, handler);
            if (
                isTestMethod
                && isTestPath
                && isTestParams
            ) {
                try {
                    const response = handler.handle(fullPath, init);
                    if (response != null) {
                        if (response instanceof Promise) {
                            return response;
                        }
                        return Promise.resolve(new Response(JSON.stringify(response), {status: 200}));
                    }
                } catch (error) {
                    if ("status" in error && "code" in error && "message" in error) {
                        const errorJson: RestErrorJson = {
                            code: error.code,
                            message: error.message
                        };
                        return Promise.resolve(new Response(JSON.stringify(errorJson), {status: error.status}));
                    } else {
                        const errorJson: RestErrorJson = {
                            code: RestError.errorCodes.unexpected,
                            message: error.message
                        };
                        return Promise.resolve(new Response(JSON.stringify(errorJson), {status: 500}));
                    }
                }
            }
        }
    }

    return null;
};

import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import { State } from "../../../state/State";
import { rejectionHandler, RejectionReducer } from "../../rejectionHandler";
import { getStorageValue } from "../../../communication/storage";
import { GetStorageValueRequest, GetStorageValueResponse } from "../../../communication/dtos/storage/GetStorageValue";

export const type = "getStorageValue";

export interface GetStorageValueActionResponse {
    key: string;
    response: GetStorageValueResponse;
}

export const actionCreator = promisePattern.actionCreator(type, (request: GetStorageValueRequest) => {
    return getStorageValue(request).then((response: GetStorageValueResponse) => {
        return new Promise((resolve) => {
            resolve({key: request.key, response});
        });
    });
});

const onFulfilled: promisePattern.PromiseFulfilledHandler<GetStorageValueActionResponse> = (state, payload) => {
    return sprout.assoc(state,
        ["server", "kvStorage", "values", payload?.key], payload?.response,
        ["server", "kvStorage", "fetching"], false,
        ["server", "kvStorage", "error"], null
    );
};

const rejected: RejectionReducer = {
    reducer: (state, error) => sprout.deepMerge<State>(state, {
        server: {
            kvStorage: {
                fetching: false,
                error: error.message
            }
        }
    })
};

const onRejected = rejectionHandler([rejected]);

const onPending: promisePattern.PromisePendingHandler =
    (state) => sprout.deepMerge<State>(state, {
        server: {
            kvStorage: {
                fetching: true,
                error: null
            }
        }
    });

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);
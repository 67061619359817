import * as restAccess from "./utils/restAccess";
import {UpdatePasswordRequest, UpdatePasswordResponse} from "./dtos/auth/UpdatePassword";
import {GetLongLivedTokenResponse} from "./dtos/auth/GetLongLivedTokenResponse";
import {ToggleModeRequest, ToggleModeResponse} from "./dtos/auth/ToggleMode";
import {GetCreditCardResponse} from "./dtos/user/payments/GetCard";
import {DeleteCreditCardResponse} from "./dtos/user/payments/DeleteCard";
import {AddCreditCardRequest, AddCreditCardResponse} from "./dtos/user/payments/AddCard";
import {cleanNulls} from "../util/communicationUtils";

const userEndpointPrefix = "/v2/user";
const accountEndpointPrefix = "/v2/account";

export const updatePassword =
    (values: UpdatePasswordRequest): Promise<UpdatePasswordResponse> =>

        restAccess.fetchJson(`${userEndpointPrefix}/changePassword`, {}, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(cleanNulls(values))
        });

export const getLongLivedToken = (): Promise<GetLongLivedTokenResponse> => restAccess.fetchJson("/v1/userAccount", {},
    {
        method: "GET",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        },
    });

export const toggleTesting = (values: ToggleModeRequest): Promise<ToggleModeResponse> =>
    restAccess.fetchJson(`${accountEndpointPrefix}/switch`, {}, {
        method: "POST", headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(cleanNulls(values))
    });

//[ CREDIT CARD ADD/GET/DELETE ]
export const addCreditCard = (values: AddCreditCardRequest): Promise<AddCreditCardResponse> =>
    restAccess.fetchJson(`${accountEndpointPrefix}/payments/card`, {}, {
        method: "POST", headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(cleanNulls(values))
    });

export const getCreditCard = (): Promise<GetCreditCardResponse> =>
    restAccess.fetchJson(`${accountEndpointPrefix}/payments/card`, {}, {
        method: "GET"
    });

export const deleteCreditCard = (): Promise<DeleteCreditCardResponse> =>
    restAccess.fetchJson(`${accountEndpointPrefix}/payments/card`, {}, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json"
        },
    });
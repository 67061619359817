export const getLocationRoot = (location?: string): string => {
    if (!location) {
        location = getLocation();
    }

    if (!!location && location !== "/") {
        return `/${(location.charAt(0) === "/") ? location.split("/")[1] : location.split("/")[0]}`;
    } else {
        return "/";
    }
};

export const getLocation = (hash?: string): string => {
    const locationRegex = /\#([^\? \n\r]*)/;
    const matches = hash ? hash.match(locationRegex) : window.location.hash.match(locationRegex);

    return (matches && matches.length > 1) ? matches[1] : "";
};

export const getHash = (hash?: string): string => {
    const hashRegex = /(\#[^\? \n\r]*)/;
    const matches = hash ? hash.match(hashRegex) : window.location.hash.match(hashRegex);

    return (matches && matches.length > 1) ? matches[1] : "";
};
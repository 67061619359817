import * as React from "react";
import * as ReactDOM from "react-dom";
import {Provider} from "react-redux";
import ReduxPromise from "redux-promise-middleware";
import ReduxThunk from "redux-thunk";
import * as Redux from "redux";
import "whatwg-fetch";
import {reducer} from "./actions/docsMasterReducer";
import {compose} from "redux";

// Include the favicons in the build.
require("./android-chrome-512x512.png");
require("./android-chrome-192x192.png");
require("./apple-touch-icon.png");
require("./favicon-16x16.png");
require("./favicon-32x32.png");
require("./favicon.ico");
require("./mstile-150x150.png");
require("./safari-pinned-tab.svg");
require("./manifest.json");

const store = Redux.createStore(
    reducer,
    null,
    window.devToolsExtension
        ? compose(
            window.devToolsExtension,
            Redux.applyMiddleware(
                ReduxThunk,
                ReduxPromise
            )
        )
        : Redux.applyMiddleware(
            ReduxThunk,
            ReduxPromise
        )
);
const rootElement = document.getElementById("root");

let render = () => {
    // Requiring App here and not importing at the top enables hot reloading.
    const App = (require("./connectedComponents/DocsApp")).Docs;
    ReactDOM.render(
        <Provider store={store}>
            <App store={store}/>
        </Provider>,
        rootElement
    );
};

declare const module: any;
if (module.hot) {
    // Support hot reloading of components
    // and display an overlay for runtime errors
    const renderApp = render;
    render = () => {
        try {
            renderApp();
        } catch (error) {
            const RedBox = require("redbox-react");
            ReactDOM.render(
                <RedBox error={error}/>,
                rootElement
            );
        }
    };
    module.hot.accept("./connectedComponents/DocsApp", () => {
        setTimeout(render);
    });
}

render();

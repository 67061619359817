export interface StripeModalUIState {
    isOpen: boolean;
    errorMessage?: string;
}

export interface PaymentUIState {
    stripeModal: StripeModalUIState;
    successModalIsOpen: boolean;
    deleteCardConfirmIsOpen: boolean;
}

export interface AccountUiState {
    isTransactionLimitDialogOpen?: boolean;
    isContactSalesSuccessOpen?: boolean;
    isChangePasswordModalOpen?: boolean;
    payment: PaymentUIState;
}

export const InitialUserAccountUiState = {
    payment: {
        deleteCardConfirmIsOpen: false,
        stripeModal: {
            isOpen: false,
            errorMessage: ""
        },
        successModalIsOpen: false
    }
};
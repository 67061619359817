import * as restAccess from "./utils/restAccess";
import {CreateApiKeyRequest, CreateApiKeyResponse} from "./dtos/user/apiKeys/CreateApiKey";
import {GetApiKeysResponse} from "./dtos/user/apiKeys/GetApiKeys";
import {DeleteApiKeysRequest, DeleteApiKeysResponse} from "./dtos/user/apiKeys/DeleteApiKey";
import {GetApiKeyRequest, GetApiKeyResponse} from "./dtos/user/apiKeys/GetApiKey";
import {cleanNulls} from "../util/communicationUtils";

const userApiPrefix = "/v2/account/apiKeys";

/////////// API KEY /////////////
/////////////////////////////////
// Api Key Create
export const createApiKey = (values: CreateApiKeyRequest): Promise<CreateApiKeyResponse> =>
    restAccess.fetchJson(`${userApiPrefix}`, {}, {
        method: "POST", headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(cleanNulls(values))
    });

// Api Keys Get
export const getApiKeys = (): Promise<GetApiKeysResponse> => restAccess.fetchJson(`${userApiPrefix}`);
export const getApiKey = (params: GetApiKeyRequest): Promise<GetApiKeyResponse> => restAccess.fetchJson(`${userApiPrefix}/${params.tokenId}`);

// Api Key Delete
export const deleteApiKey = (params: DeleteApiKeysRequest): Promise<DeleteApiKeysResponse> =>
    restAccess.fetchJson(`${userApiPrefix}/${params.tokenId}`, {}, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json"
        },
    });
import {BaseServerState} from "../BaseServerState";

export const StorageKeys = {
    FTUE_WELCOME_SHOWN: "ftue_welcomeShown",
    FTUE_TRIGGER_DROPIN_SETUP: "ftue_trigger_dropin_setup",
    HIDE_TEST_MODE_BANNER: "hide_test_mode_banner",
    TURNKEY_CONFIG: "turnkeyPublicConfig",
    JWT_SECRET: "jwtSecret",
};

export interface KVStorageState extends BaseServerState {
    keys?: string[];
    values?: {
        turnkeyPublicConfig?: any;
        ftue_welcomeShown?: any;
        hide_test_mode_banner?: any;
        [key: string]: any;
    };
}

export const InitialKVStorageServerState: KVStorageState = {
    keys: [],
    values: {}
};
import {ActionCreator, Reducer} from "./Action";
import * as sprout from "sprout-data";
import * as promisePattern from "./promisePattern";
import {UiActionPaths, UiStateList} from "../state/uistate/UiActionPaths";

/**
 * The goal of this reducer is to save time making quick updates to the ui state, things like toggling a single boolean
 * It's much less safe or prescriptive then a dedicated action
 * If you need to update an object or complex values a dedicated action should be used
 */

//TODO: Only allow simple types, remove {[key:string]: any}
export type UiActionValue = string | number | boolean | { [key: string]: any };

export interface UiActionParams {
    uiStatePath: UiActionPaths;
    value: UiActionValue;
}

export interface ActionCreatorArgs extends UiActionParams {
}

export const type = "uiAction";

//TODO [DO] Find a way to provide more type safety around the value param
export const actionCreator: ActionCreator<ActionCreatorArgs, UiActionParams> = (args) => {
    return {type: type, payload: args};
};

export const reducer: Reducer<UiActionParams> = (state, action) => {
    const uiPath = UiStateList[action.payload?.uiStatePath];

    if (!!uiPath) {
        const path = ["ui"].concat(uiPath);
        return sprout.assoc(state, path, action.payload?.value);
    } else {
        throw Error("Trying to Use a UiStatePath that does not exist! " + action.payload?.uiStatePath);
    }
};

export const reducerMap = promisePattern.reducerMap(type, reducer);
export enum ValueTabs {
    TRANSACTIONS = "EVENTS",
    CONTACTS = "CONTACTS",
    PERFORMANCE = "PERFORMANCE"
}

export enum ProgramTabs {
    OVERVIEW = "OVERVIEW",
    VALUES = "VALUES",
    GENERIC = "GENERIC",
    CODES = "CODES",
    PERFORMANCE = "PERFORMANCE"
}

export interface SelectedTabsUiState {
    programTab: ProgramTabs;
    valueTab: ValueTabs;

    [key: string]: string;
}

export const InitialSelectedTabsUiState = {
    programTab: ProgramTabs.OVERVIEW,
    valueTab: ValueTabs.TRANSACTIONS
};
import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {getAccountInvites} from "../../../communication/account";
import {State} from "../../../state/State";
import {rejectionHandler} from "../../rejectionHandler";
import {GetInvitedMembersResponse} from "../../../communication/dtos/account/GetInvitedMembers";

export const type = "getAccountInvites";

export const actionCreator = promisePattern.actionCreator(type, getAccountInvites);

const onFulfilled: promisePattern.PromiseFulfilledHandler<GetInvitedMembersResponse> =
    (state, payload) => sprout.assoc(state,
        ["server", "account", "invites", "invitees"], payload,
        ["server", "account", "invites", "processing"], false,
        ["server", "account", "invites", "error"], null
    );

const onRejected = rejectionHandler();

const onPending: promisePattern.PromisePendingHandler =
    (state) => sprout.deepMerge<State>(state, {
        server: {
            account: {
                invites: {
                    processing: true,
                    error: null
                }
            }
        }
    });

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);
import * as promisePattern from "../../promisePattern";
import * as userAccount from "../../../communication/userAccount";
import {GetLongLivedTokenResponse} from "../../../communication/dtos/auth/GetLongLivedTokenResponse";
import {rejectionHandler} from "../../rejectionHandler";
import {onPending} from "./accountActionPendingHandler";
import * as sprout from "sprout-data";
import {onAccountCallRejected} from "./accountActionRejectedHandler";

export const type = "getLongLivedToken";

export const actionCreator = promisePattern.actionCreator(type, userAccount.getLongLivedToken);

const onFulfilled: promisePattern.PromiseFulfilledHandler<GetLongLivedTokenResponse> =
    (state, payload) => {
        return sprout.assoc(state,
            ["server", "user", "apiKey"], payload?.longLivedToken,
            ["server", "user", "fetching"], false
        );
    };

const onRejected: promisePattern.PromiseRejectedHandler = rejectionHandler([
    onAccountCallRejected
]);

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);
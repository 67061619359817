import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import { State } from "../../../state/State";
import { rejectionHandler } from "../../rejectionHandler";
import { deleteApiKey } from "../../../communication/userApiKeys";
import { DeleteApiKeysRequest} from "../../../communication/dtos/user/apiKeys/DeleteApiKey";

export const type = "deleteApiKey";

export interface DeleteApiKeyActionResponse {
    tokenId: string;
}

export const actionCreator = promisePattern.actionCreator(type, (params: DeleteApiKeysRequest) => {
    return deleteApiKey(params).then(() => {
        return new Promise((resolve) => {
            resolve({
                tokenId: params.tokenId
            });
        });
    });
});

const onFulfilled: promisePattern.PromiseFulfilledHandler<DeleteApiKeyActionResponse> = (state, payload) => {
    const updatedKeys = state.server.user.apiKeys.keys.filter(p => p.tokenId !== payload?.tokenId);

    return sprout.assoc(state,
        ["server", "user", "apiKeys", "keys"], updatedKeys,
        ["server", "user", "apiKeys", "processing"], false,
        ["server", "user", "error"], null
    );
};

const onRejected = rejectionHandler();

const onPending: promisePattern.PromisePendingHandler =
    (state) => sprout.deepMerge<State>(state, {
        server: {
            user: {
                apiKeys: {
                    processing: true,
                    error: null
                }
            }
        }
    });

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);
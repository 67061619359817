import {DefaultTurnkeyConfig, TurnkeyConfig, TurnkeyConfigRequired} from "../serverState/keyValue/TurnkeyConfig";

export interface TurnkeyImageUpload {
    error?: string;
    uploading: boolean;
    progress?: number;
}

export interface TurnkeyUiState {
    editing: boolean;
    config: TurnkeyConfig;
    imageUpload: TurnkeyImageUpload;
    errors: TurnkeyConfigRequired;
    stripeOAuthOpen?: boolean;
}

export const InitialTurnkeyUiState: TurnkeyUiState = {
    editing: false,
    config: DefaultTurnkeyConfig,
    imageUpload: {
        error: "",
        uploading: false
    },
    errors: {
        claimLink: "",
        companyName: "",
        companyWebsiteUrl: "",
        copyright: "",
        customerSupportEmail: "",
        emailSubject: "",
        giftEmailReplyToAddress: "",
        logo: "",
        programId: "",
        termsAndConditions: "",
        linkToTerms: "",
        linkToPrivacy: ""
    }
};
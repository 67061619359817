import * as promisePattern from "../../promisePattern";
import * as userAccount from "../../../communication/userAccount";
import * as sprout from "sprout-data";
import {UpdatePasswordResponse} from "../../../communication/dtos/auth/UpdatePassword";
import {rejectionHandler} from "../../rejectionHandler";
import {onPasswordRejected} from "./passwordRejectionHandler";

export const type = "updatePassword";

export const actionCreator = promisePattern.actionCreator(type, userAccount.updatePassword);

const onFulfilled: promisePattern.PromiseFulfilledHandler<UpdatePasswordResponse> =
    (state, payload) => {
        return sprout.assoc(state,
            ["server", "auth", "updatePasswordMessage"], payload?.message,
            ["server", "auth", "errorMessage"], "",
            ["server", "auth", "processing"], false);
    };

const onRejected: promisePattern.PromiseRejectedHandler = rejectionHandler([onPasswordRejected]);

const onPending: promisePattern.PromisePendingHandler =
    (state) => sprout.assoc(state, ["server", "auth", "processing"], true);

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);
import { ActionCreator, Reducer } from "../../Action";
import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";

export interface ActionParams {
    token: string;
}

export interface ActionCreatorArgs {
    token: string;
}

export const type = "setResetPasswordToken";

export const actionCreator: ActionCreator<ActionCreatorArgs, ActionParams> = (args) => {
    return {type: type, payload: {token: args.token}};
};

export const reducer: Reducer<ActionParams> = (state: any, action: any) => {
    return sprout.assoc(state, ["server", "auth", "resetToken"], action.payload?.token);
};

export const reducerMap = promisePattern.reducerMap(type, reducer);
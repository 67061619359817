import * as restAccess from "./utils/restAccess";
import {LoginRequest, LoginResponse} from "./dtos/auth/Login";
import {ForgotPasswordRequest, ForgotPasswordResponse} from "./dtos/auth/ForgotPassword";
import {ResetPasswordRequest, ResetPasswordResponse} from "./dtos/auth/ResetPassword";
import {PingResponse} from "./dtos/auth/PingResponse";
import {User} from "./dtos/auth/User";
import {cleanNulls} from "../util/communicationUtils";

export const Endpoints = {
    Login: "/v2/user/login",
    Register: "/v2/user/register",
    ForgotPassword: "/v2/user/forgotPassword",
    ForgotPasswordComplete: "/v2/user/forgotPassword/complete",
    ResetPassword: "/v2/user/resetPassword",
    ChangeEmail: "/v2/user/changeEmail",
    CompleteChangeEmail: "/v2/user/changeEmail/complete",
    Ping: "/v2/user/ping",
    PingNoRefresh: "/v2/user/ping?refresh=false",
    GetUser: "/v2/user",
    Logout: "/v2/user/logout"
};

export const login =
    (values: LoginRequest): Promise<LoginResponse> =>
        restAccess.fetchJson(Endpoints.Login, {}, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(cleanNulls(values))
        });

export const register =
    (values: LoginRequest): Promise<LoginResponse> =>
        restAccess.fetchJson(Endpoints.Register, {}, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(cleanNulls(values))
        });

export const forgotPassword =
    (values: ForgotPasswordRequest): Promise<ForgotPasswordResponse> =>
        restAccess.fetchJson(Endpoints.ForgotPassword, {}, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(cleanNulls(values))
        });

export const resetPassword =
    (values: ResetPasswordRequest): Promise<ResetPasswordResponse> =>
        restAccess.fetchJson(Endpoints.ForgotPasswordComplete, {}, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(cleanNulls(values))
        });

export const changeEmail =
    (newEmail: string): Promise<{}> =>
        restAccess.fetchJson(Endpoints.ChangeEmail, {}, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({email: newEmail})
        });

export const ping =
    (): Promise<PingResponse> =>
        restAccess.fetchJson(Endpoints.Ping, {}, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });

export const getUser =
    (): Promise<User> =>
        restAccess.fetchJson(Endpoints.GetUser, {}, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });

export const pingNoRefresh =
    (): Promise<PingResponse> =>
        restAccess.fetchJson(Endpoints.PingNoRefresh, {}, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });

export const logout =
    (): Promise<PingResponse> =>
        restAccess.fetchJson(Endpoints.Logout, {}, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            }
        });
export enum TransactionType {DEBIT, CREDIT}

export enum TransactionAction {
    CAPTURE = "Capture",
    REVERSE = "Reverse",
    VOID = "Void",
    NONE = "noAction"
}

export interface TransactionActionState {
    currentAction: TransactionAction;
}

export interface TransactionUiState {
    actions: { [transactionId: string]: TransactionActionState };
    showButtons?: boolean;
}

export const InitialTransactionUiState: TransactionUiState = {
    actions: {}
};
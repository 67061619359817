import {BaseServerState} from "./BaseServerState";
import {Contact} from "lightrail-client/dist/model";
import {PaginatedState} from "./Pagination";

export interface ContactsServerState extends BaseServerState, PaginatedState {
    list?: Contact[];
}

export const InitialContactsServerState: ContactsServerState = {
    list: [],
    processing: false,
    fetching: false,
    pagination: {
        limit: 0,
        maxLimit: 0
    }
};
import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {State} from "../../../state/State";
import {rejectionHandler, RejectionReducer} from "../../rejectionHandler";
import {getCreditCard} from "../../../communication/userAccount";
import {GetCreditCardResponse} from "../../../communication/dtos/user/payments/GetCard";

export const type = "getCreditCard";

export const actionCreator = promisePattern.actionCreator(type, getCreditCard);

const onFulfilled: promisePattern.PromiseFulfilledHandler<GetCreditCardResponse> =
    (state, payload) => {
        return sprout.assoc(state,
            ["server", "user", "payment", "creditCard"], payload || {},
            ["server", "user", "payment", "cardProvided"], !!payload,
            ["server", "user", "payment", "fetching"], false,
            ["server", "user", "payment", "error"], null
        );
    };

const onGetCardRejected: RejectionReducer = {
    reducer: (state, error) => sprout.deepMerge<State>(state, {
        server: {
            user: {
                payment: {
                    fetching: false,
                    error: error.message,
                    cardProvided: false
                }
            }
        }
    })
};

const onRejected = rejectionHandler([onGetCardRejected]);

const onPending: promisePattern.PromisePendingHandler =
    (state) => sprout.deepMerge<State>(state, {
        server: {
            user: {
                payment: {
                    fetching: true,
                    error: null
                }
            }
        }
    });

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);
import {BaseServerState} from "./BaseServerState";
import {Account} from "../../communication/dtos/account/Account";
import {AccountUserInvitees, User} from "../../communication/dtos/account/User";

export interface AccountUser extends BaseServerState {
    list?: User[];
}

export interface AccountInvitee extends BaseServerState {
    invitees?: AccountUserInvitees[];
    lastInvited?: string;
}

export interface AccountState extends BaseServerState {
    accountInfo?: Account;
    users?: AccountUser;
    invites?: AccountInvitee;
    accounts?: Account[];
    authMessage?: {
        message?: string;
        messageCode?: string;
    };
    fetching?: boolean;
}

export const InitialTeamsServerState: AccountState = {
    fetching: false,
    processing: false,
    accountInfo: {
        name: null,
        id: null,
        requireMfa: false,
    },
    accounts: [],
    users: {
        fetching: false,
        processing: false,
        list: []
    },
    invites: {
        fetching: false,
        processing: false,
        invitees: [],
        lastInvited: ""
    }
};
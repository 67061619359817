import * as ReactRedux from "react-redux";
import {DocsApp} from "../components/DocsApp";
import {State} from "../../src/state/State";

import {actionCreator as ping} from "../../src/actions/auth/server/ping";
import {actionCreator as setUIState} from "../../src/actions/uiAction";

const mapStateToProps = (state: State): object => ({
    state: state
});

const actionCreators = {
    ping,
    setUIState
};

const DocsAppConnected = ReactRedux.connect(mapStateToProps, actionCreators as any)(DocsApp);
export {DocsAppConnected as Docs};
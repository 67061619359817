import * as Sentry from "@sentry/browser";
import {cloneDeep} from "lodash";
import {State} from "../state/State";

export const sentryUrl = "https://df16973687f84ca584efbf83236e19ef@sentry.io/111201";

export function logSentryException(error: any): void {
    Sentry.captureException(error);
}

let contextEmail: string = null;

export function setSentryUserContext(email: string): void {
    contextEmail = email;
}

export function clearSentryUserContext(): void {
    contextEmail = null;
}

Sentry.configureScope(scope => {
    scope.setUser({email: contextEmail});
});

export function scrubState(state: State): State {
    const newState = cloneDeep(state);
    newState.server.user.apiKey = null;
    newState.server.user.payment.creditCard = null;
    newState.server.user.apiKey = null;
    newState.server.user.apiKeys.keys = null;

    return newState;
}
import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {getAccountUser} from "../../../communication/account";
import {State} from "../../../state/State";
import {rejectionHandler, RejectionReducer} from "../../rejectionHandler";
import {GetUserResponse} from "../../../communication/dtos/account/GetUser";

export const type = "getAccountUser";

export const actionCreator = promisePattern.actionCreator(type, getAccountUser);

const onFulfilled: promisePattern.PromiseFulfilledHandler<GetUserResponse> =
    (state, payload) => {
        return (sprout.assoc(state,
            ["server", "account", "users", "list"], [...state.server.account.users.list, payload],
            ["server", "account", "users", "processing"], false,
            ["server", "account", "users", "error"], null
        ));
    };

const on403Reducer: RejectionReducer = {
    status: 403,
    overrideGlobal: true,
    reducer: (state) => sprout.assoc(state,
        ["server", "account", "users", "processing"], false,
    )
};

const onRejected = rejectionHandler([on403Reducer]);

const onPending: promisePattern.PromisePendingHandler =
    (state) => sprout.deepMerge<State>(state, {
        server: {
            account: {
                users: {
                    fetching: true,
                    error: null
                }
            }
        }
    });

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);
import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import { State } from "../../../state/State";
import { rejectionHandler, RejectionReducer } from "../../rejectionHandler";
import { deleteCreditCard } from "../../../communication/userAccount";
import { DeleteCreditCardResponse } from "../../../communication/dtos/user/payments/DeleteCard";

export const type = "deleteCreditCard";

export const actionCreator = promisePattern.actionCreator(type, deleteCreditCard);

const onFulfilled: promisePattern.PromiseFulfilledHandler<DeleteCreditCardResponse> =
    (state) => {
        return sprout.assoc(state,
            ["server", "user", "payment", "creditCard"], null,
            ["server", "user", "payment", "cardProvided"], false,
            ["server", "user", "payment", "deleting"], false,
            ["server", "user", "payment", "error"], null
        );
    };

const onDeleteCardRejected: RejectionReducer = {
    reducer: (state, error) => sprout.deepMerge<State>(state, {
        server: {
            user: {
                payment: {
                    deleting: false,
                    error: error.message
                }
            }
        }
    })
};

const onRejected = rejectionHandler([onDeleteCardRejected]);

const onPending: promisePattern.PromisePendingHandler =
    (state) => sprout.deepMerge<State>(state, {
        server: {
            user: {
                payment: {
                    deleting: true,
                    error: null
                }
            }
        }
    });

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);
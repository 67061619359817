import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {createAccountUser} from "../../../communication/account";
import {State} from "../../../state/State";
import {CreateUserResponse} from "../../../communication/dtos/account/CreateUser";
import {rejectionHandler, RejectionReducer} from "../../rejectionHandler";

export const type = "createAccountUser";

export const actionCreator = promisePattern.actionCreator(type, createAccountUser);

const onFulfilled: promisePattern.PromiseFulfilledHandler<CreateUserResponse> = (state, payload) => {
    return sprout.assoc(state,
        ["server", "account", "invites", "invitees", "lastInvited"], payload?.email,
        ["server", "account", "invites", "processing"], false,
        ["server", "account", "invites", "error"], null
    );
};

const onCreateTeamMember400: RejectionReducer = {
    status: 400,
    overrideGlobal: true,
    reducer: (state, error) => sprout.deepMerge<State>(state, {
        server: {
            account: {
                invites: {
                    processing: false,
                    error: error.message
                }
            }
        }
    })
};

const onCreateTeamMemberRejected: RejectionReducer = {
    reducer: (state, error) => sprout.deepMerge<State>(state, {
        server: {
            account: {
                invites: {
                    processing: false,
                    error: error.message
                }
            }
        }
    })
};

const onRejected = rejectionHandler([onCreateTeamMemberRejected, onCreateTeamMember400]);

const onPending: promisePattern.PromisePendingHandler =
    (state) => sprout.deepMerge<State>(state, {
        server: {
            account: {
                invites: {
                    processing: true,
                    error: null
                }
            }
        }
    });

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);
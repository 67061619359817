export interface DocumentationFile {
    id: string;
    url: string;
}

export const getDocumentationFiles = (isProductionEnvironment: boolean): DocumentationFile[] => {
    const branch = isProductionEnvironment ? "master" : "development";
    const urlRootToUse = "https://raw.githubusercontent.com/Giftbit/Lightrail-API-V2-Docs/" + branch;

    return FileDefinitions.map(definition => ({
        ...definition,
        url: urlRootToUse + definition.url
    }));
};

const FileDefinitions = [
    {
        id: "welcome",
        url: "/docs/welcome.md"
    },
    {
        id: "object-model",
        url: "/docs/object-model.md"
    },
    {
        id: "use-cases",
        url: "/docs/use-cases.md"
    },
    {
        id: "currency-and-points",
        url: "/docs/currency-and-points.md"
    },
    {
        id: "checkout-integration",
        url: "/docs/checkout.md"
    },
    {
        id: "wallet",
        url: "/docs/wallet.md"
    },
    {
        id: "webhooks",
        url: "/docs/webhooks.md"
    },
    {
        id: "advanced",
        url: "/docs/advanced.md"
    },
    {
        id: "client-libraries",
        url: "/docs/client-libraries.md"
    },
    {
        id: "reference",
        url: "/docs/api-reference.md"
    },
    {
        id: "support",
        url: "/docs/support.md"
    }
];
import {BaseServerState} from "./BaseServerState";
import {Webhook} from "../../communication/dtos/webhooks/Webhook";

export interface WebhookServerState extends BaseServerState {
    list: Webhook[];
    secret: Secret;
}

export const InitialWebhookServerState: WebhookServerState = {
    list: [],
    fetching: false,
    secret: {
        processing: false
    }

};

export interface Secret extends BaseServerState {
}

export interface CurrencyUiEditState {
    code: string;
    open: boolean;
}

export interface CurrencyUiState {
    edit: CurrencyUiEditState;
    createModalOpen: boolean;
}

export const InitialCurrencyUiState: CurrencyUiState = {
    edit: {
        code: "",
        open: false
    },
    createModalOpen: false
};
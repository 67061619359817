import {Value} from "lightrail-client/dist/model/Value";
import {BaseServerState} from "./BaseServerState";
import {PaginatedState, PaginationLimits} from "./Pagination";
import {ValueStats} from "../../communication/dtos/values/ValueStats";
import {Links} from "../../communication/links/Links";

export interface ValueStatsServerState extends BaseServerState {
    data: ValueStats;
}

export interface ValueServerState extends BaseServerState, PaginatedState {
    list?: Value[];
    attachedList?: Value[];
    text?: string;
    fetchingCSV?: boolean;
    fetchingAttached?: boolean;
    stats?: ValueStatsServerState;
    paginationAttached?: PaginationLimits;
    linksAttached?: Links;
}

export const InitialValueServerState: ValueServerState = {
    fetching: false,
    fetchingAttached: false,
    fetchingCSV: false,
    list: [],
    attachedList: [],
    text: "",
    pagination: {
        maxLimit: 0,
        limit: 0
    },
    paginationAttached: {
        maxLimit: 0,
        limit: 0
    },
    stats: {
        data: null,
        fetching: false
    }
};
import {Transaction} from "lightrail-client/dist/model";
import {BaseServerState} from "./BaseServerState";

export interface TransactionChainServerState extends BaseServerState {
    chains: {
        [id: string]: Transaction[];
    };
}

export interface TransactionsServerState extends BaseServerState {
    list?: Transaction[];
    transactionChains?: TransactionChainServerState;
}

export const InitialTransactionsServerState: TransactionsServerState = {
    list: [],
    fetching: false,
    pagination: {
        maxLimit: 0,
        limit: 0
    },
    transactionChains: {
        chains: {},
        fetching: false
    }
};
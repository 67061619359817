import {Issuance} from "lightrail-client/dist/model/Issuance";

export interface IssuanceCreationUIState {
    open?: boolean;
    limitUses?: boolean;
    createdNotificationList?: Issuance[];
    pollingList?: IssuancePollingRequest[];
}

export interface IssuanceUiState {
    create?: IssuanceCreationUIState;
}

export const InitialIssuanceUiState: IssuanceUiState = {
    create: {
        open: false,
        limitUses: false,
        createdNotificationList: [],
        pollingList: []
    }
};

export interface IssuancePollingRequest {
    programId: string;
    issuanceId: string;
    requestDate: Date;
}
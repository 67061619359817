import * as React from "react";
import {LightrailDocMarkdown} from "./LightrailDocMarkdown";

export interface Props {
}

export class MainLayout extends React.PureComponent<Props, {}> {
    render(): JSX.Element {
        return (
            <LightrailDocMarkdown tocMaxDepth={3} documentSlicingMaxDepth={2}/>
        );
    }
}

import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {State} from "../../../state/State";
import {rejectionHandler} from "../../rejectionHandler";
import {GetApiKeyResponse} from "../../../communication/dtos/user/apiKeys/GetApiKey";
import {getApiKey} from "../../../communication/userApiKeys";

export const type = "getApiKey";

export const actionCreator = promisePattern.actionCreator(type, getApiKey);

const onFulfilled: promisePattern.PromiseFulfilledHandler<GetApiKeyResponse> =
    (state, payload) => sprout.assoc(state,
        ["server", "user", "apiKeys", "keys"], [...state.server.user.apiKeys.keys, payload],
        ["server", "user", "apiKeys", "fetchCount"], state.server.user.apiKeys.fetchCount + 1,
        ["server", "user", "apiKeys", "fetching"], false,
        ["server", "user", "error"], null
    );

const onRejected = rejectionHandler();

const onPending: promisePattern.PromisePendingHandler =
    (state) => sprout.deepMerge<State>(state, {
        server: {
            user: {
                apiKeys: {
                    fetching: true,
                    error: null
                }
            }
        }
    });

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);
export class RestError extends Error {
    /**
     * HTTP status code for the error.
     */
    status: number;

    payload: any;

    /**
     * A code for the exact type of error.
     */
    code: string;

    responseData: any;

    constructor(status: number, errorJson: RestErrorJson) {
        super(errorJson.message);
        this.status = status;
        this.code = errorJson.code;
        this.payload = {};
        this.responseData = errorJson;
    }

    static errorCodes = {
        authentication: "authentication",
        invalidParam: "invalidParam",
        missingParam: "missingParam",
        notFound: "notFound",
        unexpected: "unexpected"
    };
}

export interface RestErrorJson {
    message: string;
    code: string;
    payload?: any;
}
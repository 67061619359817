import {ENVIRONMENTS, getEnvironment} from "./getEnvironment";

export const getCustomerSupportEmail = (): string => {
    switch (getEnvironment()) {
        case ENVIRONMENTS.LOCALHOST:
            return process.env["LIGHTRAIL_EMAIL"];
        case ENVIRONMENTS.PRODUCTION:
            return "hello+cancel@lightrail.com";
        case ENVIRONMENTS.DEV:
        case ENVIRONMENTS.STAGING:
        default:
            return "dev@lightrail.com";
    }
};

export const cleanNulls = <T extends Record<string, any>>(obj: T): T => {
    if (!obj) {
        return null;
    }

    const cleaned = {...obj};

    Object.keys(obj).forEach(key => {
        const property: any = obj[key];

        if (property === null || property === undefined) {
            delete cleaned[key];
        }
    });

    return cleaned;
};
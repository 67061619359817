import * as promisePattern from "../../promisePattern";
import * as auth from "../../../communication/auth";
import * as sprout from "sprout-data";
import {deepMerge} from "sprout-data";
import {UpdatePasswordResponse} from "../../../communication/dtos/auth/UpdatePassword";
import {rejectionHandler} from "../../rejectionHandler";
import {onPasswordRejected} from "./passwordRejectionHandler";

export const type = "forgotPassword";

export const actionCreator = promisePattern.actionCreator(type, auth.forgotPassword);

const onFulfilled: promisePattern.PromiseFulfilledHandler<UpdatePasswordResponse> =
    (state, payload) => {
        if (payload?.message) {
            return sprout.assoc(state, ["server", "auth", "successMessage"], payload?.message);
        } else {
            return sprout.assoc(state, ["server", "auth", "successMessage"], "If that account exists on our system, an email will be sent with instructions to reset your password");
        }
    };

const onRejected: promisePattern.PromiseRejectedHandler = rejectionHandler([onPasswordRejected]);

const onPending: promisePattern.PromisePendingHandler =
    (state) => {
        return deepMerge(state, {
            server: {
                auth: {
                    processing: true,
                    errorMessage: []
                }
            }
        });
    };

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);
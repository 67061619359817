import * as sprout from "sprout-data";
import { dissoc } from "sprout-data";
import * as promisePattern from "../../promisePattern";
import { State } from "../../../state/State";
import { rejectionHandler } from "../../rejectionHandler";
import { deleteStorageValue } from "../../../communication/storage";
import { DeleteStorageValueRequest, DeleteStorageValueResponse } from "../../../communication/dtos/storage/DeleteStorageValue";

export const type = "deleteStorageValue";

export interface DeleteStorageValueActionResponse {
    key: string;
    response: DeleteStorageValueRequest;
}

export const actionCreator = promisePattern.actionCreator(type, (request: DeleteStorageValueRequest) => {
    return deleteStorageValue(request).then((response: DeleteStorageValueResponse) => {
        return new Promise((resolve) => {
            resolve({key: request.key, response});
        });
    });
});

const onFulfilled: promisePattern.PromiseFulfilledHandler<DeleteStorageValueActionResponse> = (state, payload) => {
    return sprout.assoc(state,
        ["server", "kvStorage", "values"], dissoc(state.server.kvStorage.values, [payload?.key]),
        ["server", "kvStorage", "fetching"], false,
        ["server", "kvStorage", "error"], null
    );
};

const onRejected = rejectionHandler();

const onPending: promisePattern.PromisePendingHandler =
    (state) => sprout.deepMerge<State>(state, {
        server: {
            kvStorage: {
                fetching: true,
                error: null
            }
        }
    });

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);
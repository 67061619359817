import * as promisePattern from "../../promisePattern";
import * as auth from "../../../communication/auth";
import {PingResponse} from "../../../communication/dtos/auth/PingResponse";
import * as sprout from "sprout-data";
import {rejectionHandler} from "../../rejectionHandler";

export const type = "ping";

export const actionCreator = promisePattern.actionCreator(type, auth.ping);

const onFulfilled: promisePattern.PromiseFulfilledHandler<PingResponse> =
    (state) => {
        return sprout.assoc(state,
            ["server", "user", "loggedIn"], true
        );
    };

const onRejected = rejectionHandler();

const onPending: promisePattern.PromisePendingHandler =
    (state) => {
        return state;
    };

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);
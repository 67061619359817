import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {resendAccountUserInvite} from "../../../communication/account";
import {State} from "../../../state/State";
import {ResendMemberInviteResponse} from "../../../communication/dtos/account/ResendMemberInvite";
import {rejectionHandler} from "../../rejectionHandler";

export const type = "resendAccountUserInvite";

export const actionCreator = promisePattern.actionCreator(type, resendAccountUserInvite);

const onFulfilled: promisePattern.PromiseFulfilledHandler<ResendMemberInviteResponse> = (state) => {
    return sprout.assoc(state,
        ["server", "account", "processing"], false,
        ["server", "account", "error"], null
    );
};

const onRejected = rejectionHandler();

const onPending: promisePattern.PromisePendingHandler =
    (state) => sprout.deepMerge<State>(state, {
        server: {
            account: {
                invites: {
                    processing: true,
                    error: null
                }
            }
        }
    });

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);
export interface WebhookUiState {
    editModalOpen: boolean;
    createModalOpen: boolean;
    showSecretModalOpen: boolean;
    createSecretModalOpen: boolean;
}

export const InitialWebhookUiState: WebhookUiState = {
    editModalOpen: false,
    createModalOpen: false,
    showSecretModalOpen: false,
    createSecretModalOpen: false
};
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/*
 * Typescript is disabled for a file because actionCreator doesn't agree with Typescript.
 * This was moved to a separate file so only this function would be left out of type checking.
 *
 * actionCreatorUntyped creates an action and each action will be bound to dispatch automatically by react-redux.
 * This means that the actionCreator should return an Action (ie {type: string, payload: TPayload}).
 * In reality when the creator is called the return of the dispatched action is returned.
 * The actual return type within code using an action has the shape {
 *  action: {
 *      type: string,
 *      payload: TPayload
 *  },
 *  value: TPayload
 * }
 *
 * The alternative here is to typecast the return of an action whenever it's used, which seems more error prone than
 *      this approach.
 */

import {PromiseCreator} from "./promisePattern";
import {ActionCreator} from "./Action";

/**
 * Create an ActionCreator from the given PromiseCreator.  Actions will be dispatched
 * for the pending, fulfilled and rejected state changes of the Promise.
 * @param type
 * @param promiseCreator
 */
export const actionCreatorUntyped: ActionCreator<TArgs, TPayload> =
    <TArgs, TPayload>(type: string, promiseCreator: PromiseCreator<TArgs, TPayload>): Action<TPayload> =>
        (args: TArgs) => ({
            type: type,
            payload: promiseCreator(args)
        });
export interface MFAUiState {
    isMFAMethodModalOpen?: boolean;
    MFAMethod?: string;
    isMFAVerifyOpen?: boolean;
    phoneNumberSuccess?: boolean;
    QRCodeSuccess?: boolean;
    CodeOneAccepted?: boolean;
    MFAComplete?: boolean;
    MFAIncompleteMessage?: string;
    QRCodeModalOpen?: boolean;
    device?: string;
    deviceArea?: string;
    illegalDeviceMessage?: string;
    incorrectCodeMessage?: string;
    successDialog?: boolean;
}

export const InitialMFAUiState: MFAUiState = {};
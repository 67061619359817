import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {State} from "../../../state/State";
import {rejectionHandler, RejectionReducer} from "../../rejectionHandler";
import {addCreditCard} from "../../../communication/userAccount";
import {CreditCard} from "../../../communication/dtos/user/payments/CreditCard";
import {AddCreditCardResponse} from "../../../communication/dtos/user/payments/AddCard";

export const type = "addPaymentCard";

export const actionCreator = promisePattern.actionCreator(type, addCreditCard);

const failedCheck = "fail";
const genericErrorMsg = "There was an error with your card details.";
const addressErrorMsg = "There was an error with your address.";

const onFulfilled: promisePattern.PromiseFulfilledHandler<AddCreditCardResponse> =
    (state, payload) => {
        let newState = state;

        const card: CreditCard = payload.card;
        let error: string = null;

        //Set Error message for address checks
        if (card.addressLine1Check === failedCheck) {
            error = "Address line 1 check failed";
        } else if (card.addressZipCheck === failedCheck) {
            error = "Address zip check failed";
        } else if (card.cvcCheck === failedCheck) {
            error = "CVC check failed";

            //Set cvc error in createToken with other Card errors
            newState = sprout.assoc(newState,
                ["server", "user", "payment", "createToken", "error"], genericErrorMsg
            );
        }

        const addressError = (!!error) ? addressErrorMsg : null;
        return sprout.assoc(newState,
            ["server", "user", "payment", "creditCard"], card,
            ["server", "user", "payment", "cardProvided"], !!card,
            ["server", "user", "payment", "processing"], false,
            ["server", "user", "payment", "addressError"], addressError,
            ["server", "user", "payment", "error"], error
        );
    };

const onAddCardRejected: RejectionReducer = {
    status: 400,
    overrideGlobal: true,
    reducer: (state, error) => sprout.deepMerge<State>(state, {
        server: {
            user: {
                payment: {
                    processing: false,
                    error: error.message,
                    createToken: {
                        error: genericErrorMsg
                    }
                }
            }
        }
    })
};

const onRejected = rejectionHandler([onAddCardRejected]);

const onPending: promisePattern.PromisePendingHandler =
    (state) => sprout.deepMerge<State>(state, {
        server: {
            user: {
                payment: {
                    processing: true,
                    addressError: null,
                    error: null
                }
            }
        }
    });

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);
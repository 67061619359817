import {Contact, Program, Transaction, Value} from "lightrail-client/dist/model";
import {BaseServerState} from "./BaseServerState";

export enum SearchId {
    GLOBAL = "global"
}

export enum SearchType {EVERYTHING, PROGRAMS, VALUES, CONTACTS, TRANSACTIONS}

export interface SearchResults {
    programs?: Program[];
    contacts?: Contact[];
    values?: Value[];
    transactions?: Transaction[];
}

export interface SearchState extends BaseServerState {
    searchedValue: string;
    searchedType: SearchType;
    results: SearchResults;
}

export interface SearchServerState {
    [key: string]: SearchState;
}

export const InitialSearchServerState: SearchServerState = {
    [SearchId.GLOBAL]: {
        fetching: false,
        fetchCount: 0,
        searchedValue: "",
        searchedType: SearchType.EVERYTHING,
        results: {
            programs: [],
            contacts: [],
            values: [],
            transactions: []
        }
    }
};
import {BaseServerState} from "./BaseServerState";
import {Program} from "lightrail-client/dist/model";
import {PaginatedState} from "./Pagination";
import {ProgramStats} from "../../communication/dtos/program/ProgramStats";

export interface ProgramsServerState extends BaseServerState, PaginatedState {
    allList?: Program[];
    activeList?: Program[];
    inactiveList?: Program[];
    fetchingActive?: boolean;
    fetchingInactive?: boolean;
    stats?: { fetching: boolean, data?: ProgramStats };
}

export const InitialProgramServerState: ProgramsServerState = {
    allList: [],
    activeList: [],
    inactiveList: [],
    stats: {fetching: false, data: null},
    fetching: false,
    fetchingActive: false,
    fetchingInactive: false,
    pagination: {
        maxLimit: 0,
        limit: 0
    }
};
import {CreditCard} from "../../communication/dtos/user/payments/CreditCard";
import {SubscriptionTier} from "../../communication/dtos/userInformation/SubscriptionTier";
import {BaseServerState} from "./BaseServerState";
import {ApiKey, CreatedApiKey} from "../../communication/dtos/user/apiKeys/ApiKey";
import StripeTokenResponse = stripe.StripeTokenResponse;

export interface ApiKeyState extends BaseServerState {
    lastCreatedKey?: CreatedApiKey;
    keys?: ApiKey[];
}

export interface PaymentState {
    fetching?: boolean;
    processing?: boolean;
    deleting?: boolean;
    createToken?: CreateToken;
    status?: PaymentStatus;
    cardProvided?: boolean;
    creditCard?: CreditCard;
    addressError?: string;
    error?: string;
}

export interface PaymentStatus {
    fetching: boolean;
    result: number;
    message: string;
}

export interface CreateToken {
    processing?: boolean;
    response?: StripeTokenResponse;
    error?: string;
}

export interface SubscriptionState {
    fetching?: boolean;
    fetchCount?: number;
    subscriptionTier?: SubscriptionTier;
}

export interface UserState {
    apiKeys?: ApiKeyState;
    email?: string;
    apiKey?: string;
    loggedIn?: boolean;
    payment?: PaymentState;
    testing?: boolean;
    fetching?: boolean;
    subscription?: SubscriptionState;
    additionalAuthenticationRequired?: boolean;
    id?: string;
}

export const InitialUserServerState: UserState = {
    fetching: false,
    loggedIn: false,
    email: "",
    payment: {
        createToken: {
            processing: false,
            error: ""
        },
        creditCard: {}
    },
    subscription: {
        fetchCount: 0,
    },
    apiKeys: {
        processing: false,
        fetching: false,
        fetchCount: 0,
        lastCreatedKey: null,
        keys: [],
    },
    additionalAuthenticationRequired: true
};
export interface ContactCustomerSupportState {
    requestsFulfilled?: number;
    processing?: boolean;
    error?: string;
}

export interface ContactUsState {
    contactCustomerSupport?: ContactCustomerSupportState;
}

export const InitialContactUsServerState = {
    contactCustomerSupport: {
        requestsFulfilled: 0,
        processing: false
    }
};
// @ts-nocheck
import * as React from "react";
import * as remarkParse from "remark-parse";
import {HeadingNode} from "remark-parse";
import {Heading} from "./sideBar/HeadingsNav";
import {Sidebar} from "./sideBar/Sidebar";
import {TabbedCodeSnippets} from "./tabbedCodeSnippet/TabbedCodeSnippets";
import {MarkdownHeading} from "./markdown/MarkdownHeading";
import {getHash} from "../../src/util/location";
import {getDocumentationFiles} from "../DocumentationFilesConfig";
import {isProductionEnvironment} from "../../src/util/getEnvironment";
// disbale eslint for legacy styles
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-enable @typescript-eslint/no-var-requires */
// Disable typescript because some legacy libraries have no type definitions
import * as unified from "unified";
import * as defaultRenderers from "react-markdown/lib/renderers";
import * as getDefinitions from "react-markdown/lib/get-definitions";
import * as astToReact from "react-markdown/lib/ast-to-react";
import "../styles/app.scss";
import flatMap = require("lodash.flatmap");
import findLastIndex = require("lodash.findlastindex");

export interface Props {
    /**
     * The max heading number to display in the table of contents.
     */
    tocMaxDepth: number;
    /**
     * The max heading number to slice a document down to in the content pane.
     */
    documentSlicingMaxDepth: number;
}

export interface State {
    docs: MarkdownDoc[];
}

export interface MarkdownDoc {
    /**
     * The ID is used to link to documents and should not change.
     */
    id: string;
    /**
     * The URL of the raw markdown file.
     */
    url: string;
    ast?: remarkParse.RootNode;
    headings?: Heading[];
}

export class LightrailDocMarkdown extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        const docs = getDocumentationFiles(isProductionEnvironment());
        this.state = {
            docs
        };
        window.addEventListener("hashchange", () => {
            this.forceUpdate(() => {
                this.scrollToHeader();
                LightrailDocMarkdown.setPageTitle();
            });
        }, false);
        window.addEventListener("load", () => {
            this.scrollToHeader();
            LightrailDocMarkdown.setPageTitle();
        });
    }

    scrollToHeader(): void {
        if (window.location.hash) {
            const element = document.getElementById(window.location.hash.substring(1));
            if (element) {
                element.scrollIntoView();
            }
        }
    }

    componentDidMount(): void {
        this.fetchDocs();
    }

    static setPageTitle(title?: string): void {
        if (!title) {
            const hash = getHash().substr(1) || "Welcome";
            title = hash.split("/").map(name => name.charAt(0).toUpperCase() + name.substr(1)).join(" · ");
        }
        document.title = `${title} | Lightrail Docs`;
    }

    async fetchDocs(): Promise<void> {
        this.state.docs.forEach(async (doc, ix) => {
            if (doc.ast) {
                return;
            }
            const fetchedDoc = await this.fetchDocContent(doc);
            const state = {
                ...this.state,
                docs: [
                    ...this.state.docs.slice(0, ix),
                    fetchedDoc,
                    ...this.state.docs.slice(ix + 1)
                ]
            };
            this.setState(state);
        });
    }

    async fetchDocContent(doc: MarkdownDoc): Promise<MarkdownDoc> {
        const response = await fetch(doc.url);
        const text = await response.text();
        if (response.status !== 200) {
            console.error(`Looks like there was a problem. status=${response.status} response=${text}`);
            return doc;
        }
        let ast = unified().use(remarkParse).parse(text);
        ast = TabbedCodeSnippets.astPlugin(ast);
        ast = MarkdownHeading.astPlugin(doc.id, ast);
        return {
            ...doc,
            ast,
            headings: this.getHeadings(doc.id, ast)
        };
    }

    getHeadings(docId: string, tree: remarkParse.RootNode): Heading[] {
        const headings: Heading[] = [];
        const headingStack: Heading[] = [];
        for (const node of tree.children) {
            if (node.type === "heading") {
                const headingNode = node as HeadingNode;
                if (this.props.tocMaxDepth && headingNode.depth > this.props.tocMaxDepth) {
                    continue;
                }
                const heading: Heading = {
                    text: MarkdownHeading.getHeadingText(headingNode),
                    link: "#" + MarkdownHeading.getHeadingLinkHash(docId, headingNode),
                    level: headingNode.depth,
                    children: []
                };
                while (headingStack.length && headingStack[headingStack.length - 1].level >= heading.level) {
                    headingStack.pop();
                }
                if (headingStack.length) {
                    headingStack[headingStack.length - 1].children.push(heading);
                } else {
                    headings.push(heading);
                }
                headingStack.push(heading);
            }
        }
        return headings;
    }

    private getAstRenderRange(hash: string, ast: remarkParse.RootNode): { astStart: number, astEnd: number } {
        if (!ast || !hash) {
            return {
                astStart: 0,
                astEnd: ast ? ast.children.length : 0
            };
        }
        if (hash.startsWith("#")) {
            hash = hash.substring(1);
        }
        const headingIx = ast.children.findIndex(node => node.type === "heading" && (node as any).value.link === hash);
        if (headingIx === -1) {
            return {
                astStart: 0,
                astEnd: ast.children.length
            };
        }
        const astStart = findLastIndex(ast.children, node => node.type === "heading" && (node as remarkParse.HeadingNode).depth <= this.props.documentSlicingMaxDepth, headingIx);
        if (astStart === -1) {
            return {
                astStart: 0,
                astEnd: ast.children.length
            };
        }
        const astEnd = ast.children.findIndex((node, ix) => ix > astStart && node.type === "heading" && (node as remarkParse.HeadingNode).depth <= (ast.children[astStart] as remarkParse.HeadingNode).depth);
        if (astEnd === -1) {
            return {
                astStart,
                astEnd: ast.children.length
            };
        }
        return {
            astStart,
            astEnd
        };
    }

    render(): JSX.Element {
        const hash = window.location.hash;
        const hashParts = hash && /#([\w-]+)\/([\w-]+)/i.exec(hash);
        const docId = hashParts && hashParts[1];
        const doc = this.state.docs.find(d => d.id === docId) || this.state.docs[0];
        const {astStart, astEnd} = this.getAstRenderRange(hash, doc.ast);
        return (
            <div className="container">
                <Sidebar headings={flatMap(this.state.docs, d => d.headings || [])}/>
                <div className="docs-content-container">
                    {
                        doc.ast && astToReact(
                            {
                                ...doc.ast,
                                children: doc.ast.children.slice(astStart, astEnd)
                            },
                            {
                                renderers: {
                                    ...defaultRenderers,
                                    mergedCode: TabbedCodeSnippets,
                                    heading: MarkdownHeading
                                },
                                definitions: doc.ast && getDefinitions(doc.ast)
                            }
                        )
                    }
                </div>
            </div>
        );
    }
}
// The following enums are used by the uiAction action (src/actions/uiAction.ts)
// to map to the state path from UiStateList
export enum UiActionPaths {
    ACCOUNTS_CONTACT_SALES_SUCCESS_OPEN,
    ACCOUNTS_TRANSACTION_LIMIT_OPEN,

    BULK_TAG_OPEN,
    BULK_TAG_VALUES,

    CARDS_DETAIL_CANCEL_OPEN,
    CARDS_DELIVERING,
    CARDS_CHANGE_OWNER_OPEN,
    CARDS_SET_RESEND_EMAIL,
    CARDS_DELIVER_DIALOG_OPEN,

    COMP_TEST_BANNER_OPEN,

    CONTACTS_NEW_ACCCOUNT_CURRENCY,
    CONTACT_SET_ACCOUNT_DIALOG_OPEN,
    CONTACTS_TRANSACTIONS_SELECTED_CARDID,
    CONTACT_SET_EDIT_DIALOG_OPEN,
    CONTACT_SET_CREATE_DIALOG_OPEN,
    CONTACT_ATTACH_VALUE_OPEN,
    CONTACT_CREATE_OPEN,

    CURRENCY_MODAL_OPEN,

    DROPIN_PREVIEW_SET_JWT,
    DROPIN_PREVIEW_SET_REDEEM_CODE,
    DROPIN_PREVIEW_REDEEM_OPEN,
    DROPIN_PREVIEW_SET_THEME,
    DROPIN_PREVIEW_PURCHASE_READY,

    FTUE_SET_WELCOME_OPEN,
    FTUE_DROPIN__SETUP_DIALOG_OPEN,
    FTUE_DROPIN_SETUP_ACTIVE,

    GLOBAL_SEARCH_OPEN,
    GLOBAL_SEARCH_VALUE,

    ISSUANCE_CREATE_OPEN,

    INTEGRATOR_VIEW_MODAL,
    LAYOUT_SCREEN_SIZE,
    NAV_VIEW_SEARCH,
    NAV_MENU_OPEN,

    PASSWORD_VISIBLE,
    PROGRAM_CREATE_CARDS,
    PROGRAM_CREATE_DRAWER_OPEN,
    PROGRAM_EDIT_NAME,
    PROGRAM_VIEW_TERMS,
    PROGRAM_TRANSACTION_VIEW_EXPORT_SUCCESS,

    REPORTS_TRANSACTIONS_SELECTED_PROGRAMID,
    REPORTS_TRANSACTIONS_EXPORT_SUCCESS,

    SEARCH_OBJECTS_CARD_TAG_ENTRY_OPEN,
    SEARCH_OBJECTS_CONTACT_TAG_ENTRY_OPEN,

    SESSION_EXPIRED_DIALOG_OPEN,

    SET_SELECTED_PROGRAM_TAB,
    SET_SELECTED_VALUE_TAB,

    SHOW_COPY_ID_BUTTON,

    TEAM_CONFIRM_GENERATE_TOKEN,
    TEAM_GENERATE_KEY_OPEN,
    TEAM_VIEW_KEY_OPEN,
    TEAM_MEMBER_TO_EDIT,

    TEAM_INVITE_MEMBER_OPEN,
    TEAM_INVITE_DIALOG_OPEN,

    TRANSACTION_MODAL_OPEN,
    TRANSACTION_BUTTONS_SHOW,

    VALUE_CREATE_GENERIC_OPEN,

    WEBHOOK_CREATE_MODAL_OPEN,
    WEBHOOK_EDIT_MODAL_OPEN,
    WEBHOOK_CREATE_NEW_SECRET_MODAL_OPEN,
    WEBHOOK_SHOW_SECRET_MODAL_OPEN,
}

//Map the enum to the path it modifies from state.ui
export const UiStateList: string[][] = [];

UiStateList[UiActionPaths.ACCOUNTS_CONTACT_SALES_SUCCESS_OPEN] = ["account", "isContactSalesSuccessOpen"];
UiStateList[UiActionPaths.ACCOUNTS_TRANSACTION_LIMIT_OPEN] = ["account", "isTransactionLimitDialogOpen"];

UiStateList[UiActionPaths.BULK_TAG_OPEN] = ["components", "bulk", "cards", "tag", "isOpen"];
UiStateList[UiActionPaths.BULK_TAG_VALUES] = ["components", "bulk", "cards", "tag", "tags"];

UiStateList[UiActionPaths.CARDS_DETAIL_CANCEL_OPEN] = ["cards", "cardDetails", "cancelConfirmOpen"];
UiStateList[UiActionPaths.CARDS_DELIVERING] = ["cards", "deliverCard", "deliveringCard"];
UiStateList[UiActionPaths.CARDS_CHANGE_OWNER_OPEN] = ["cards", "deliverCard", "changeOwnerOpen"];
UiStateList[UiActionPaths.CARDS_SET_RESEND_EMAIL] = ["cards", "deliverCard", "resendEmail"];
UiStateList[UiActionPaths.CARDS_DELIVER_DIALOG_OPEN] = ["cards", "deliverCard", "deliverCardDialogOpen"];

UiStateList[UiActionPaths.COMP_TEST_BANNER_OPEN] = ["components", "testModeBanner", "open"];

UiStateList[UiActionPaths.CONTACTS_NEW_ACCCOUNT_CURRENCY] = ["contacts", "newAccount", "currency"];
UiStateList[UiActionPaths.CONTACT_SET_ACCOUNT_DIALOG_OPEN] = ["contacts", "newAccount", "dialogOpen"];
UiStateList[UiActionPaths.CONTACTS_TRANSACTIONS_SELECTED_CARDID] = ["contacts", "transactions", "selectedCardId"];
UiStateList[UiActionPaths.CONTACT_SET_EDIT_DIALOG_OPEN] = ["contacts", "editDialogOpen"];
UiStateList[UiActionPaths.CONTACT_SET_CREATE_DIALOG_OPEN] = ["contacts", "createDialogOpen"];
UiStateList[UiActionPaths.CONTACT_ATTACH_VALUE_OPEN] = ["contacts", "attachValue", "open"];
UiStateList[UiActionPaths.CONTACT_CREATE_OPEN] = ["contacts", "createContactOpen"];

UiStateList[UiActionPaths.CURRENCY_MODAL_OPEN] = ["currency", "createModalOpen"];

UiStateList[UiActionPaths.DROPIN_PREVIEW_SET_JWT] = ["turnKey", "dropinPreview", "jwt"];
UiStateList[UiActionPaths.DROPIN_PREVIEW_REDEEM_OPEN] = ["turnKey", "dropinPreview", "redemption", "dashboardDialogOpen"];
UiStateList[UiActionPaths.DROPIN_PREVIEW_SET_REDEEM_CODE] = ["turnKey", "dropinPreview", "redemption", "code"];
UiStateList[UiActionPaths.DROPIN_PREVIEW_SET_THEME] = ["turnKey", "dropinPreview", "theme"];
UiStateList[UiActionPaths.DROPIN_PREVIEW_PURCHASE_READY] = ["turnKey", "dropinPreview", "cardPurchase", "ready"];

UiStateList[UiActionPaths.FTUE_SET_WELCOME_OPEN] = ["ftue", "welcomeDialogOpen"];
UiStateList[UiActionPaths.FTUE_DROPIN__SETUP_DIALOG_OPEN] = ["ftue", "dropinSetup", "dialogOpen"];
UiStateList[UiActionPaths.FTUE_DROPIN_SETUP_ACTIVE] = ["ftue", "dropinSetup", "active"];

UiStateList[UiActionPaths.GLOBAL_SEARCH_OPEN] = ["search", "global", "open"];
UiStateList[UiActionPaths.GLOBAL_SEARCH_VALUE] = ["search", "global", "value"];

UiStateList[UiActionPaths.ISSUANCE_CREATE_OPEN] = ["issuance", "create", "open"];

UiStateList[UiActionPaths.INTEGRATOR_VIEW_MODAL] = ["programs", "integratorNameModal"];
UiStateList[UiActionPaths.LAYOUT_SCREEN_SIZE] = ["layout", "screenSize"];
UiStateList[UiActionPaths.NAV_VIEW_SEARCH] = ["navBar", "searchDialogOpen"];
UiStateList[UiActionPaths.NAV_MENU_OPEN] = ["navBar", "menuOpen"];

UiStateList[UiActionPaths.PASSWORD_VISIBLE] = ["components", "passwordVisible"];
UiStateList[UiActionPaths.PROGRAM_CREATE_CARDS] = ["programs", "createCardsOpen"];
UiStateList[UiActionPaths.PROGRAM_CREATE_DRAWER_OPEN] = ["programs", "createDrawerOpen"];
UiStateList[UiActionPaths.PROGRAM_EDIT_NAME] = ["programs", "nameEditMode"];
UiStateList[UiActionPaths.PROGRAM_VIEW_TERMS] = ["programs", "termsDialogOpen"];
UiStateList[UiActionPaths.PROGRAM_TRANSACTION_VIEW_EXPORT_SUCCESS] = ["programs", "transactionExportSuccessOpen"];

UiStateList[UiActionPaths.REPORTS_TRANSACTIONS_SELECTED_PROGRAMID] = ["reports", "transactions", "selectedProgramId"];
UiStateList[UiActionPaths.REPORTS_TRANSACTIONS_EXPORT_SUCCESS] = ["reports", "transactionExportSuccessOpen"];

UiStateList[UiActionPaths.SESSION_EXPIRED_DIALOG_OPEN] = ["components", "session", "expiredOpen"];

UiStateList[UiActionPaths.SEARCH_OBJECTS_CARD_TAG_ENTRY_OPEN] = ["search", "objects", "cards", "isTagEntryOpen"];
UiStateList[UiActionPaths.SEARCH_OBJECTS_CONTACT_TAG_ENTRY_OPEN] = ["search", "objects", "contacts", "isTagEntryOpen"];

UiStateList[UiActionPaths.SET_SELECTED_PROGRAM_TAB] = ["selectedTabs", "programTab"];
UiStateList[UiActionPaths.SET_SELECTED_VALUE_TAB] = ["selectedTabs", "valueTab"];

UiStateList[UiActionPaths.SHOW_COPY_ID_BUTTON] = ["copyButton"];

UiStateList[UiActionPaths.TEAM_CONFIRM_GENERATE_TOKEN] = ["team", "confirmGenerateTokenOpen"];
UiStateList[UiActionPaths.TEAM_GENERATE_KEY_OPEN] = ["user", "apiKeys", "createKeyOpen"];
UiStateList[UiActionPaths.TEAM_VIEW_KEY_OPEN] = ["user", "apiKeys", "viewNewKeyOpen"];
UiStateList[UiActionPaths.TEAM_MEMBER_TO_EDIT] = ["team", "teamMemberToUpdate"];

UiStateList[UiActionPaths.TEAM_INVITE_MEMBER_OPEN] = ["team", "inviteTeamMemberOpen"];
UiStateList[UiActionPaths.TEAM_INVITE_DIALOG_OPEN] = ["team", "inviteTeamMemberDialogOpen"];

UiStateList[UiActionPaths.TRANSACTION_MODAL_OPEN] = ["transactions", "actions"];
UiStateList[UiActionPaths.TRANSACTION_BUTTONS_SHOW] = ["transactions", "showButtons"];

UiStateList[UiActionPaths.VALUE_CREATE_GENERIC_OPEN] = ["value", "createGenericCode", "open"];

UiStateList[UiActionPaths.WEBHOOK_CREATE_MODAL_OPEN] = ["webhook", "createModalOpen"];
UiStateList[UiActionPaths.WEBHOOK_EDIT_MODAL_OPEN] = ["webhook", "editModalOpen"];
UiStateList[UiActionPaths.WEBHOOK_SHOW_SECRET_MODAL_OPEN] = ["webhook", "showSecretModalOpen"];
UiStateList[UiActionPaths.WEBHOOK_CREATE_NEW_SECRET_MODAL_OPEN] = ["webhook", "createSecretModalOpen"];
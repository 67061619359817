import {UiState} from "./uiState/UiState";
import {ServerState} from "./serverState/ServerState";
import {InitialLayoutUiState} from "./uistate/LayoutUiState";
import {InitialTurnkeyUiState} from "./uistate/TurnkeyUiState";
import {InitialComponentUiState} from "./uistate/ComponentUiState";
import {InitialTurnkeyServerState} from "./serverState/TurnkeyServerState";
import {InitialTeamsServerState} from "./serverState/AccountState";
import {InitialUserServerState} from "./serverState/User";
import {InitialMFAServerState} from "./serverState/MFAState";
import {InitialAuthServerState, InitialRegisterServerState} from "./serverState/AuthServerState";
import {InitialKVStorageServerState} from "./serverState/keyValue/KVStorageState";
import {InitialIntercomServerState} from "./serverState/IntercomState";
import {InitialContactUsServerState} from "./serverState/ContactUsState";
import {InitialUserAccountUiState} from "./uistate/AccountUiState";
import {InitialFtueUiState} from "./uistate/FtueUiState";
import {InitialTeamsUiState} from "./uistate/TeamsUiState";
import {InitialNavBarUiState} from "./uistate/NavBarUiState";
import {InitialUserUiState} from "./uistate/UserUiState";
import {InitialMFAUiState} from "./uistate/MFAUiState";
import {InitialContactsServerState} from "./serverState/ContactsServerState";
import {InitialProgramServerState} from "./serverState/ProgramsServerState";
import {InitialSelectedTabsUiState} from "./uistate/SelectedTabsUiState";
import {InitialValueServerState} from "./serverState/ValueServerState";
import {InitialIssuanceServerState} from "./serverState/IssuanceServerState";
import {InitialIssuanceUiState} from "./uistate/IssuanceUiState";
import {InitialValueUiState} from "./uistate/ValueUiState";
import {InitialSearchServerState} from "./serverState/SearchesServerState";
import {InitialSearchUiState} from "./uistate/SearchUiState";
import {InitialTransactionsServerState} from "./serverState/TransactionsServerState";
import {BrowserState, InitialBrowserState} from "./browserState/BrowserState";
import {InitialContactUIState} from "./uistate/ContactUiState";
import {InitialCurrencyServerState} from "./serverState/CurrencyServerState";
import {InitialCurrencyUiState} from "./uistate/CurrencyUiState";
import {InitialProgramUiState} from "./uistate/ProgramUiState";
import {InitialTransactionUiState} from "./uistate/TransactionUiState";
import {InitialGenerateReportServerState} from "./serverState/GenerateReportServerState";
import {InitialWebhookServerState} from "./serverState/WebhookServerState";
import {InitialWebhookUiState} from "./uistate/WebhookUiState";

export interface State {
    ui?: UiState;
    server?: ServerState;
    form?: any;
    browser?: BrowserState;
}

export const initialState: State = {
    ui: {
        account: InitialUserAccountUiState,
        components: InitialComponentUiState,
        currency: InitialCurrencyUiState,
        contacts: InitialContactUIState,
        errors: {
            restErrorAlertOpen: false
        },
        ftue: InitialFtueUiState,
        history: [],
        issuance: InitialIssuanceUiState,
        layout: InitialLayoutUiState,
        navBar: InitialNavBarUiState,
        programs: InitialProgramUiState,
        search: InitialSearchUiState,
        selectedTabs: InitialSelectedTabsUiState,
        team: InitialTeamsUiState,
        transactions: InitialTransactionUiState,
        turnKey: InitialTurnkeyUiState,
        MFA: InitialMFAUiState,
        user: InitialUserUiState,
        value: InitialValueUiState,
        copyButton: true,
        webhook: InitialWebhookUiState,
    },
    server: {
        auth: InitialAuthServerState,
        contacts: InitialContactsServerState,
        contactUs: InitialContactUsServerState,
        currency: InitialCurrencyServerState,
        generateReport: InitialGenerateReportServerState,
        intercom: InitialIntercomServerState,
        issuance: InitialIssuanceServerState,
        kvStorage: InitialKVStorageServerState,
        programs: InitialProgramServerState,
        register: InitialRegisterServerState,
        search: InitialSearchServerState,
        account: InitialTeamsServerState,
        transactions: InitialTransactionsServerState,
        turnkey: InitialTurnkeyServerState,
        MFA: InitialMFAServerState,
        user: InitialUserServerState,
        values: InitialValueServerState,
        webhook: InitialWebhookServerState,
    },
    browser: InitialBrowserState,
    form: {}
};
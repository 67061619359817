// ACTION DROPDOWNS
export enum ValueActionStatus {
    ACTIVATE = "vasActivate",
    FREEZE = "vasFreeze",
    UNFREEZE = "vasUnfreeze",
    CANCEL = "vasCancel",
    UNCANCEL = "vasUncancel",
    DEBIT = "vasDebit",
    CREDIT = "vasCredit",
    DEBIT_USES = "vasDebitUses",
    CREDIT_USES = "vasCreditUses",
    DEBIT_ATTACHES = "vasDebitAttaches",
    CREDIT_ATTACHES = "vasCreditAttaches",
    SHOW_FULLCODE = "vasFullcode",
    CHANGE_CODE = "vasChangeCode",
    ATTACH_CONTACT = "attachContact",
    DETACH_CONTACT = "detachContact",
    NO_ACTION = "vasNoAction",
    MODIFY_DATES = "vasModifyDates",
}

export interface ValueActionsDropDown {
    showFullCodeModalOpen?: boolean;
    transactionModalOpen?: boolean;
    changeCodeModalOpen?: boolean;
    modifyDatesModalOpen?: boolean;
    pendingValueAction?: ValueActionStatus;
}

export interface ValueActionDropdowns {
    [valueId: string]: ValueActionsDropDown;
}

// CREATE
export interface CreateGenericCodeUIState {
    open: boolean;
}

// ValueUIState
export interface ValueUiState {
    createGenericCode: CreateGenericCodeUIState;
    actionDropdowns: ValueActionDropdowns;
}

export const InitialValueUiState: ValueUiState = {
    createGenericCode: {
        open: false
    },
    actionDropdowns: {}
};

// @ts-nocheck
import * as React from "react";
import {Menu} from "antd";
import {MenuInfo} from "rc-menu/es/interface";
import * as ClickParam from "antd/lib/menu";

export interface Props {
    headings: Heading[];
}

interface State {
    newHeadingLoaded: boolean;
    openKeys: string[];
}

export interface Heading {
    text: string;
    link: string;
    level: number;
    children: Heading[];
}

export class HeadingsNav extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {newHeadingLoaded: false, openKeys: []};

        this.onSubMenuClick = this.onSubMenuClick.bind(this);
        this.onMenuSelect = this.onMenuSelect.bind(this);
        this.onOpenChange = this.onOpenChange.bind(this);
        this.onItemClick = this.onItemClick.bind(this);
    }

    componentDidUpdate(nextProps: Props): void {
        const newHeadings = (nextProps.headings.length > this.props.headings.length);
        this.setState({
            newHeadingLoaded: newHeadings
        });
    }

    onSubMenuClick(params: { key: string }): void {
        window.location.href = params.key;
    }

    onMenuSelect(params: MenuInfo): void {
        window.location.href = params.key as string;
    }

    onItemClick(params: ClickParam): void {
        const hasNoChild = this.props.headings.find(h => h.link === params.key);
        if (!!hasNoChild) {
            this.setState({
                openKeys: []
            });
        }
    }

    onOpenChange(openKeys: string[]): void {
        let updatedOpenKeys: string[];
        updatedOpenKeys = [];
        updatedOpenKeys[0] = openKeys[openKeys.length - 1];
        const isChild = this.props.headings.find(h => {
            if (!!h.children) {
                h.children.find(c => {
                    if (c.link == openKeys[openKeys.length - 1]) {
                        updatedOpenKeys[1] = h.link;
                    }
                });
            }
        });
        this.setState({openKeys: updatedOpenKeys});
    }

    //TODO Create local state to track open Sub-menus so that we can open to a deeplinked section when the app is open

    renderHeading(heading: Heading): JSX.Element {
        let renderedChildren: JSX.Element[];
        if (!!heading.children.length) {
            renderedChildren = heading.children.map((heading, index) => this.renderHeading(heading, index));
            return (
                <Menu.SubMenu
                    key={heading.link}
                    title={heading.text}
                    onTitleClick={this.onSubMenuClick}
                >
                    {
                        renderedChildren
                    }
                </Menu.SubMenu>
            );
        } else {
            return (
                <Menu.Item key={heading.link}
                           onClick={this.onItemClick}>
                    {
                        heading.text
                    }
                </Menu.Item>
            );
        }
    }

    render(): JSX.Element {
        return (
            <Menu
                mode="inline"
                onClick={this.onMenuSelect}
                openKeys={this.state.openKeys}
                onOpenChange={this.onOpenChange}
            >
                {
                    (this.props.headings) &&
                    this.props.headings.map((heading, index) => (this.renderHeading(heading, index)))
                }
            </Menu>
        );
    }
}

export interface GlobalSearchUiState {
    open?: boolean;
}

export interface SearchStateUiState {
    global: GlobalSearchUiState;
}

export const InitialSearchUiState = {
    global: {
        open: false
    }
};
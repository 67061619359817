import {BackupCode} from "../../communication/dtos/auth/MFA/GetBackupCodes";

export enum mfaType {
    PHONE = "Phone",
    APP = "App",
    SMS = "sms"
}

export interface MFAState {
    error?: string;
    pending?: boolean;
    processing?: boolean;
    device?: string;
    backupCodes?: BackupCode[];
    enabled?: boolean;
    secret?: string;
    uri?: string;
    mfa?: string;
}

export const InitialMFAServerState: MFAState = {};

import * as React from "react";
import SyntaxHighlighter from "react-syntax-highlighter";

export interface Props {
    language: string;
    code: string;
}

export class CodeSnippet extends React.PureComponent<Props, {}> {
    constructor(props: Props) {
        super(props);
    }

    static preprocess(text: string): string  {
        return text.replace("<LightrailAPIKey>", "lightrail-api-key-value");
    }

    render(): JSX.Element {
        return (
            <div>
                <SyntaxHighlighter language={this.props.language}
                                   showLineNumbers={true}>
                    {CodeSnippet.preprocess(this.props.code)}
                </SyntaxHighlighter>
            </div>
        );
    }
}

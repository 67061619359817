import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import { State } from "../../../state/State";
import { rejectionHandler, RejectionReducer } from "../../rejectionHandler";
import { updateStorageValue } from "../../../communication/storage";
import { GetStorageValueResponse } from "../../../communication/dtos/storage/GetStorageValue";
import { UpdateStorageValueRequest, UpdateStorageValueResponse } from "../../../communication/dtos/storage/UpdateStorageValue";

export const type = "updateStorageValue";

export interface UpdateStorageValueActionResponse {
    key: string;
    value: any;
    response: GetStorageValueResponse;
}

export const actionCreator = promisePattern.actionCreator(type, (request: UpdateStorageValueRequest) => {
    return updateStorageValue(request).then((response: UpdateStorageValueResponse) => {
        return new Promise((resolve) => {
            resolve({key: request.key, value: request.value, response});
        });
    });
});

const onFulfilled: promisePattern.PromiseFulfilledHandler<UpdateStorageValueActionResponse> = (state, payload) => {
    const keys = (state.server.kvStorage.keys.indexOf(payload?.key) !== -1) ? state.server.kvStorage.keys : state.server.kvStorage.keys.concat([payload?.key]);
    return sprout.assoc(state,
        ["server", "kvStorage", "keys"], keys,
        ["server", "kvStorage", "values", payload?.key], payload?.value,
        ["server", "kvStorage", "processing"], false,
        ["server", "kvStorage", "error"], null
    );
};

const rejected: RejectionReducer = {
    reducer: (state, error) => sprout.deepMerge<State>(state, {
        server: {
            kvStorage: {
                processing: false,
                error: error.message
            }
        }
    })
};

const onRejected = rejectionHandler([rejected]);

const onPending: promisePattern.PromisePendingHandler =
    (state) => sprout.deepMerge<State>(state, {
        server: {
            kvStorage: {
                processing: true,
                error: null
            }
        }
    });

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);
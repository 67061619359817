import {UrlParts} from "./UrlParts";
import {QueryParamObject} from "./QueryParamObject";

const tempAnchor: HTMLAnchorElement = undefined;
const getAnchor = (): HTMLAnchorElement => tempAnchor || document.createElement("a");

/**
 * The effective maximum URL length, as dictated by IE.
 */
export const maxUrlLength = 2083;

const getQuery = (href: string): string => {
    const queryIx = href.indexOf("?");
    const fragmentIx = href.indexOf("#");

    if (queryIx != -1) {
        if (fragmentIx != -1) {
            if (queryIx < fragmentIx) {
                return href.substring(queryIx, fragmentIx);
            } else {
                return href.substring(queryIx);
            }
        }
        return href.substring(queryIx);
    }
    return "";
};

export const getUrlParts = (url: string | Request): UrlParts => {
    const a = getAnchor();
    a.href = (typeof url === "string") ? url : url.url;

    // IE doesn't populate all link properties when setting .href with a relative URL,
    // however .href will return an absolute URL which then can be used on itself
    // to populate these additional fields.
    if (a.host == "") {
        a.href = a.href; // eslint-disable-line
    }

    return {
        fragment: a.hash,
        host: a.host,
        hostname: a.hostname,
        href: a.href,
        path: a.pathname,
        port: a.port,
        protocol: a.protocol,
        query: getQuery(a.href)
    };
};

export const appendQuery = (url: string, query: string | QueryParamObject): string => {
    const queryString = typeof query === "string" ? query : getQueryString(query);
    if (!queryString.length) {
        return url;
    }

    const queryIx = url.indexOf("?");
    const hashIx = url.indexOf("#");

    if (queryIx == -1) {
        if (hashIx == -1) {
            return url + queryString;
        } else {
            return url.substring(0, hashIx) + queryString + url.substring(hashIx);
        }
    } else {
        if (hashIx == -1) {
            return url + "&" + queryString.substring(1);
        } else {
            return url.substring(0, hashIx) + "&" + queryString.substring(1) + url.substring(hashIx);
        }
    }
};

const querySpaceMatcher = /\+/g;
const queryParamMatcher = /([^&=]+)=?([^&]*)/g;
const encodedSpaceMatcher = /%20/g;

const decodeQueryParam = (param: string): string => decodeURIComponent(param.replace(querySpaceMatcher, " "));

export const getQueryParams = (url: string | Request): { [key: string]: string } => {
    let query = getUrlParts(url).query;
    if (!query || query[0] !== "?") {
        return {};
    }
    query = query.substr(1);

    const queryParams: { [key: string]: string } = {};
    let match: RegExpMatchArray = queryParamMatcher.exec(query);
    while (match) {
        queryParams[decodeQueryParam(match[1])] = decodeQueryParam(match[2]);
        match = queryParamMatcher.exec(query);
    }

    return queryParams;
};

/**
 * Get the URL query string for the given params, including the leading question mark `?`
 * if the query is not empty.
 * @param query
 * @returns {any}
 */
export const getQueryString = (query: QueryParamObject): string => {
    if (!query) {
        return "";
    }

    const paramString = Object.keys(query)
        .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(query[key].toString()))
        .join("&")
        .replace(encodedSpaceMatcher, "+");

    if (paramString.length) {
        return "?" + paramString;
    }
    return "";
};

const randString = (multiplier: number = 1000000000): string => (
    Math.floor((1 + Math.random()) * multiplier).toString(16)
);

export const generateGBTId = (): string => {
    return "GBT-" + generateId();
};

export const generateSecret = (): string => {
    return ("LRS" + randString() + randString(100000000000)).toUpperCase();
};

export const generateId = (): string => {
    return randString() + "-" + randString() + "-" + randString();
};

export const generateDefaultTeamName = (): string => {
    return "Team-" + randString();
};

export const buildKeyValueStatOwner = (key: string, value: string): string => {
    return `${key}-${value}`;
};

export const genCatStatName = (key: string, value: string): string => (
    "cat-" + key + "-" + value
);

export const genProgramOrderCatKey = (programId: string): string => (
    `program_${programId}_order`
);

export const reverseSort = (sort: string): string => (`-${sort}`);
export const isReverseSort = (sort: string): boolean => (sort.charAt(0) === "-");
export const sortKeyAbs = (sort: string): string => (isReverseSort(sort) ? sort.substr(1) : sort);
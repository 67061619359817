import { ActionCreator, Reducer } from "../../Action";
import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";

export interface ActionParams {
}

export interface ActionCreatorArgs {
}
export const type = "resetAuthErrorAndSuccessStates";

export const actionCreator: ActionCreator<ActionCreatorArgs, ActionParams> = (args) => ({
    type,
    payload: args
});

export const reducer: Reducer<ActionParams> = (state) => {
    return sprout.assoc(state,
        ["server", "auth"], {},
        ["server", "register"], {}
    );
};

export const reducerMap = promisePattern.reducerMap(type, reducer);
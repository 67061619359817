import { ActionCreator, Reducer } from "../../Action";
import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import StripeTokenResponse = stripe.StripeTokenResponse;

export interface ActionParams {
    processing: boolean;
    response: StripeTokenResponse;
    error: string;
}

export interface ActionCreatorArgs {
    processing?: boolean;
    response?: StripeTokenResponse;
    error?: string;
}
export const type = "setProcessingToken";

export const actionCreator: ActionCreator<ActionCreatorArgs, ActionParams> = (args) => {
    const payloadArgs: ActionParams = {processing: false, response: undefined, error: ""};
    payloadArgs.processing = (!!args.processing) ? args.processing : false;
    payloadArgs.response = (!!args.response) ? args.response : undefined;
    payloadArgs.error = (!!args.error) ? args.error : "";

    return {type, payload: payloadArgs};
};

export const reducer: Reducer<ActionParams> = (state, action) => {
    return sprout.assoc(state,
        ["server", "user", "payment", "createToken", "processing"], action.payload?.processing,
        ["server", "user", "payment", "createToken", "response"], action.payload?.response,
        ["server", "user", "payment", "createToken", "error"], action.payload?.error
    );
};

export const reducerMap = promisePattern.reducerMap(type, reducer);
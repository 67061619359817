import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {State} from "../../../state/State";
import {rejectionHandler} from "../../rejectionHandler";
import {getApiKeys} from "../../../communication/userApiKeys";
import {GetApiKeysResponse} from "../../../communication/dtos/user/apiKeys/GetApiKeys";

export const type = "getApiKeys";

export const actionCreator = promisePattern.actionCreator(type, getApiKeys);

const onFulfilled: promisePattern.PromiseFulfilledHandler<GetApiKeysResponse> =
    (state, payload) => {
        return (
            sprout.assoc(state,
                ["server", "user", "apiKeys", "keys"], payload,
                ["server", "user", "apiKeys", "fetching"], false,
                ["server", "user", "error"], null
            )
        );
    };

const onRejected = rejectionHandler();

const onPending: promisePattern.PromisePendingHandler =
    (state) => sprout.deepMerge<State>(state, {
        server: {
            user: {
                apiKeys: {
                    keys: [],
                    fetching: true,
                    error: null
                }
            }
        }
    });

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);
import {BaseServerState} from "./BaseServerState";
import {Issuance} from "lightrail-client/dist/model/Issuance";

export interface IssuanceServerState extends BaseServerState {
    list?: Issuance[];
}

export const InitialIssuanceServerState: IssuanceServerState = {
    list: [],
    fetching: false,
    pagination: {
        maxLimit: 0,
        limit: 0
    }
};
export interface JwtData {
    g: {
        gui: string;
        gmi: string;
        tmi: string;
    };
    aud: string;
    iss: string;
    jti: string;
    scopes: string[];
    roles: string[];
}

export interface Jwt {
    data: JwtData;

    [key: string]: any;
}

export interface BrowserState {
    jwt: Jwt;
}

export const InitialBrowserState: BrowserState = {
    jwt: {
        fetching: false,
        error: false,
        data: {
            g: {
                gui: "",
                gmi: "",
                tmi: ""
            },
            aud: "",
            iss: "",
            jti: "",
            scopes: [],
            roles: []
        }
    }
};
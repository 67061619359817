import * as promisePattern from "../../promisePattern";
import * as auth from "../../../communication/auth";
import {LoginResponse} from "../../../communication/dtos/auth/Login";
import * as sprout from "sprout-data";
import {initialState} from "../../../state/State";
import {clearSentryUserContext} from "../../../util/sentry";

export const type = "logout";

export const actionCreator = promisePattern.actionCreator(type, auth.logout);

const onFulfilled: promisePattern.PromiseFulfilledHandler<LoginResponse> =
    () => {
        clearSentryUserContext();
        //using initial state because of an issue with left over state on logout and back in (snackbar not going away)
        return sprout.assoc(initialState, ["server", "user", "loggedIn"], false);
    };

const onRejected: promisePattern.PromiseRejectedHandler =
    (state) => {
        return state;
    };

const onPending: promisePattern.PromisePendingHandler =
    (state) => {
        return state;
    };

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);
export enum ContactAction {
    NONE = "caNoAction",
    EDIT = "caEdit",
    CREATE_VALUE = "caCreateValue",
    ATTACH_VALUE = "caAttachValue",
    DETACH_VALUE = "caDetachValue"
}

export interface AttachValueUiState {
    open: boolean;
}

export interface CreateContactValueUiState {
    open: boolean;
    contactId?: string;
}

export interface ContactActionsState {
    currentAction: ContactAction;
}

export interface ContactUiState {
    actions: { [contactId: string]: ContactActionsState }; // actions used in ContactAction Menu and Contact Detail Page
    createContactOpen: boolean;
}

export const InitialContactUIState: ContactUiState = {
    actions: {},
    createContactOpen: false
};
import {ActionType} from "redux-promise-middleware";
import {Reducer} from "./Action";
import {ReducerMap} from "./combineReducers";
import {State} from "../state/State";
import {RestError} from "../communication/utils/errors/RestError";
import {LightrailRequestError} from "lightrail-client";

export type ActionResponse<TPayload> = { action: { type: string, payload: TPayload }, value: TPayload };

export type PromiseCreator<TArgs, TPayload> = (args: TArgs) => Promise<TPayload>;
export type PromiseFulfilledHandler<TPayload> = (state: State, payload: TPayload) => State;
export type PromiseRejectedHandler = (state: State, error: LightrailRequestError | RestError) => State;
export type PromisePendingHandler = (state: State) => State;

/**
 * Create an ActionCreator from the given PromiseCreator.  Actions will be dispatched
 * for the pending, fulfilled and rejected state changes of the Promise.
 * See comments in actionCreator.ts for why the function is typed weirdly.
 * @param type
 * @param promiseCreator
 */
import {actionCreatorUntyped} from "./actionCreator";

export const actionCreator = actionCreatorUntyped as unknown as <TArgs, TPayload>(type: string, promiseCreator: PromiseCreator<TArgs, TPayload>) => (args: TArgs) => ActionResponse<TPayload>;

/**
 * Get the type of the Action created when an Action with a Promise payload
 * is fulfilled.
 * @param type
 */
export const getFulfilledType = (type: string): string => `${type}_${ActionType.Fulfilled}`;

export const isFulfilledType = (type: string): boolean => /_FULFILLED/.test(type);

/**
 * Get the type of the Action created when an Action with a Promise payload
 * is rejected.
 * @param type
 */
export const getRejectedType = (type: string): string => `${type}_${ActionType.Rejected}`;

/**
 * Get the type of the Action created when the Action with a Promise payload
 * is pending.
 * @param type
 */
export const getPendingType = (type: string): string => `${type}_${ActionType.Pending}`;

/**
 * Create a map of Action type to Reducers from the handlers of the three
 * Promise phases.
 * @param type
 * @param fulfilledReducer
 * @param rejectedReducer
 * @param pendingReducer
 * @returns {{}}
 */

export const reducerMap =
    <TPayload>(type: string,
               fulfilledReducer?: PromiseFulfilledHandler<TPayload>,
               rejectedReducer?: PromiseRejectedHandler,
               pendingReducer?: PromisePendingHandler): ReducerMap => {
        if (!fulfilledReducer && !rejectedReducer && !pendingReducer) {
            throw new Error("No reducers specified.");
        }
        const reducers: { [type: string]: Reducer<any> } = {};
        if (fulfilledReducer) {
            reducers[`${type}_${ActionType.Fulfilled}`] = (state, action) => fulfilledReducer(state, action.payload);
        }
        if (rejectedReducer) {
            reducers[`${type}_${ActionType.Rejected}`] = (state, action) => {
                return rejectedReducer(state, action.payload);
            };
        }
        if (pendingReducer) {
            reducers[`${type}_${ActionType.Pending}`] = (state) => pendingReducer(state);
        }
        return reducers;
    };

import * as sprout from "sprout-data";
import {PromiseRejectedHandler} from "./promisePattern";
import {State} from "../state/State";
import {RestError} from "../communication/utils/errors/RestError";
import {LightrailRequestError} from "lightrail-client";

/**
 * status number - Specific status for this reducer to handle ex: 404
 * overrideGlobal boolean - Override the global handler?
 * reducer PromiseRejectedHandler
 */
export interface RejectionReducer {
    status?: number;
    overrideGlobal?: boolean;
    reducer: PromiseRejectedHandler;
}

/**
 * Provides global rejection / error handling, with the ability to provide a list of additional reducers
 * Additional Reducers can also override the base global handling, specify a specific code to be run on
 *
 * @param rejectionReducers rejectionReducers[]
 */
export const rejectionHandler = (rejectionReducers?: RejectionReducer[]): PromiseRejectedHandler => (state: State, error: LightrailRequestError | RestError) => {
    const restError = (!("isLightrailRequestError" in error)) ? error as RestError : null;

    let setGlobalState = true;
    let newState: State = state;
    if (!!rejectionReducers) {
        newState = rejectionReducers.reduce((reducedState: State, handler: RejectionReducer) => {
                if (!handler.status || (handler.status === error.status)) {
                    if (handler.overrideGlobal) {
                        setGlobalState = false;
                    }
                    return handler.reducer(reducedState, error);
                }

                return reducedState;
            },
            newState);
    }

    if (setGlobalState) {
        newState = sprout.deepMerge<State>(newState, {
            server: {
                restError: {
                    code: (!!restError) ? restError.code : "lightrailRequestError",
                    status: error.status,
                    message: error.message
                }
            }
        });
    }

    return newState;
};
import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {State} from "../../../state/State";
import {rejectionHandler} from "../../rejectionHandler";
import {deleteActiveAccountUser} from "../../../communication/account";
import {DeleteUserRequest} from "../../../communication/dtos/account/DeleteActiveUser";

export const type = "deleteActiveAccountUser";

export interface DeleteActiveMemberActionResponse {
    userId: string;
}

export const actionCreator = promisePattern.actionCreator(type, (params: DeleteUserRequest) => {
    return deleteActiveAccountUser(params).then(() => {
        return new Promise((resolve) => {
            resolve({userId: params.userId});
        });
    });
});

const onFulfilled: promisePattern.PromiseFulfilledHandler<DeleteActiveMemberActionResponse> = (state, payload) => {
    const updatedActiveMembers = state.server.account.users.list.filter(p => p.userId !== payload?.userId);

    return sprout.assoc(state,
        ["server", "account", "users", "list"], updatedActiveMembers,
        ["server", "account", "processing"], false,
        ["server", "account", "error"], null
    );
};

const onRejected = rejectionHandler();

const onPending: promisePattern.PromisePendingHandler =
    (state) => sprout.deepMerge<State>(state, {
        server: {
            account: {
                users: {
                    processing: true,
                    error: null
                }
            }
        }
    });

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);
//Fetch All
import {fetchJson} from "./utils/restAccess";
import {GetStorageKeysResponse} from "./dtos/storage/GetStorageKeys";
import {GetStorageValueRequest, GetStorageValueResponse} from "./dtos/storage/GetStorageValue";
import {UpdateStorageValueRequest, UpdateStorageValueResponse} from "./dtos/storage/UpdateStorageValue";
import {DeleteStorageValueRequest, DeleteStorageValueResponse} from "./dtos/storage/DeleteStorageValue";

export const getStorageData = (): Promise<GetStorageKeysResponse> => fetchJson("/v1/storage");

//Specific Key
export const getStorageValue = (values: GetStorageValueRequest): Promise<GetStorageValueResponse> => fetchJson(`/v1/storage/${values.key}`);

export const updateStorageValue = (values: UpdateStorageValueRequest): Promise<UpdateStorageValueResponse> =>
    fetchJson(`/v1/storage/${values.key}`, {}, {
        method: "PUT", headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(values.value, (k, v) => (!v ? null : v))
    });

export const deleteStorageValue = (values: DeleteStorageValueRequest): Promise<DeleteStorageValueResponse> =>
    fetchJson(`/v1/storage/${values.key}`, {}, {
        method: "DELETE", headers: {
            "Content-Type": "application/json"
        }
    });
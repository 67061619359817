export interface TurnkeyConfig {
    additionalInfo: string;
    claimLink: string;
    companyName: string;
    companyWebsiteUrl: string;
    copyright: string;
    currency: string;
    customerSupportEmail: string;
    emailSubject: string;
    giftEmailReplyToAddress: string;
    logo: string;
    linkToPrivacy: string;
    linkToTerms: string;
    programId: string;
    termsAndConditions: string;

    [key: string]: string;
}

export const DefaultTurnkeyConfig: TurnkeyConfig = {
    additionalInfo: "Once applied, the entire gift card amount will be stored in your account's gift card balance, which doesn't expire. Your gift card balance can't be transferred to other accounts or used to buy gift cards.",
    claimLink: null,
    companyName: "",
    companyWebsiteUrl: "",
    copyright: "",
    currency: "USD",
    customerSupportEmail: null,
    emailSubject: "You've Received a Gift Card",
    giftEmailReplyToAddress: "",
    logo: "",
    linkToPrivacy: "",
    linkToTerms: "",
    programId: "",
    termsAndConditions: ""
};

export interface TurnkeyConfigRequired {
    claimLink: string;
    companyName: string;
    companyWebsiteUrl: string;
    copyright: string;
    customerSupportEmail: string;
    emailSubject: string;
    giftEmailReplyToAddress: string;
    logo: string;
    programId: string;
    termsAndConditions: string;
    linkToPrivacy: string;
    linkToTerms: string;

    [key: string]: string;
}

export const TurnkeyRequiredFieldErrors: TurnkeyConfigRequired = {
    claimLink: "Email Claim Link",
    companyName: "Company Name",
    companyWebsiteUrl: "Company Website Url",
    copyright: "Copyright",
    customerSupportEmail: "Customer Support Email",
    emailSubject: "Email Subject",
    giftEmailReplyToAddress: "Gift Email reply-to address",
    logo: "You must upload a Logo",
    programId: "Program Must be Selected",
    termsAndConditions: "Terms and Conditions",
    linkToPrivacy: "Privacy Link",
    linkToTerms: "Terms and Conditions Link"
};
import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {State} from "../../../state/State";
import {rejectionHandler} from "../../rejectionHandler";
import {getStorageData} from "../../../communication/storage";
import {GetStorageKeysResponse} from "../../../communication/dtos/storage/GetStorageKeys";

export const type = "getStorage";

export const actionCreator = promisePattern.actionCreator(type, getStorageData);

const onFulfilled: promisePattern.PromiseFulfilledHandler<GetStorageKeysResponse> = (state, payload) => {
    return sprout.assoc(state,
        ["server", "kvStorage", "keys"], payload?.keys,
        ["server", "kvStorage", "fetching"], false,
        ["server", "kvStorage", "error"], null
    );
};

const onRejected = rejectionHandler();

const onPending: promisePattern.PromisePendingHandler =
    (state) => sprout.deepMerge<State>(state, {
        server: {
            kvStorage: {
                fetching: true,
                error: null
            }
        }
    });

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);
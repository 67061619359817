import * as React from "react";
import * as remarkParse from "remark-parse";

const styles = require("./markdown.scss") as any;

/**
 * This is dictated by remark-parse/ast-to-react except for
 * `value` which we control and is set by astPlugin().
 */
export interface Props {
    level: number;
    children: React.ReactNode[];
    value: {
        link: string;
    };
}

export class MarkdownHeading extends React.PureComponent<Props, {}> {
    /**
     * Get the text of the heading stripped of all markup.
     */
    static getHeadingText(node: remarkParse.HeadingNode): string {
        return node.children.map(MarkdownHeading.getNodeText).join("");
    }

    private static getNodeText(node: remarkParse.InlineNode): string {
        if (node.type === "text") {
            return (node as remarkParse.TextNode).value;
        } else if (node.type === "link") {
            return (node as remarkParse.LinkNode).children.map(MarkdownHeading.getNodeText).join("");
        } else if (node.type === "emphasis") {
            return (node as remarkParse.EmphasisNode).children.map(MarkdownHeading.getNodeText).join("");
        } else if (node.type === "strong") {
            return (node as remarkParse.StrongNode).children.map(MarkdownHeading.getNodeText).join("");
        } else if (node.type === "delete") {
            return (node as remarkParse.DeleteNode).children.map(MarkdownHeading.getNodeText).join("");
        }
        return "";
    }

    /**
     * Get a URL hash to represent the heading.
     */
    static getHeadingLinkHash(docId: string, node: remarkParse.HeadingNode): string {
        return docId + "/" + node.children.map(MarkdownHeading.getNodeText).join("").toLowerCase().replace(/ /g, "-");
    }

    /**
     * An astPlugin that must be run on the AST before it is rendered for this
     * renderer to function.  Adds a link name to the node value.
     */
    static astPlugin(docId: string, tree: remarkParse.RootNode): remarkParse.RootNode {
        for (let childIx = 0; childIx < tree.children.length; childIx++) {
            if (tree.children[childIx].type === "heading") {
                (tree.children[childIx] as any as Props).value = {
                    link: MarkdownHeading.getHeadingLinkHash(docId, tree.children[childIx] as remarkParse.HeadingNode)
                };
            }
        }

        return tree;
    }

    render(): JSX.Element {
        const anchor = (
            <a className={"markdownHeadingLink"} id={this.props.value.link} key={this.props.value.link}
               href={"#" + this.props.value.link}>
                &#x2318;
            </a>
        );

        return (
            React.createElement(`h${this.props.level}`, {key: this.props.value.link}, [anchor, ...this.props.children])
        );
    }
}

import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {getAccountUsers} from "../../../communication/account";
import {State} from "../../../state/State";
import {rejectionHandler, RejectionReducer} from "../../rejectionHandler";
import {GetUsersResponse} from "../../../communication/dtos/account/GetUsers";
import {errorStrings} from "../../../state/serverState/RestError";

export const type = "getAccountUsers";

export const actionCreator = promisePattern.actionCreator(type, getAccountUsers);

const onFulfilled: promisePattern.PromiseFulfilledHandler<GetUsersResponse> =
    (state, payload) => {
        return (
            sprout.assoc(state,
                ["server", "account", "users", "list"], payload,
                ["server", "account", "users", "processing"], false,
                ["server", "account", "users", "error"], null
            ));
    };

const on403Reducer: RejectionReducer = {
    status: 403,
    reducer: (state) => sprout.assoc(state,
        ["server", "account", "users", "list"], [],
        ["server", "account", "users", "processing"], false,
        ["server", "account", "users", "error"], errorStrings.PERMISSION_ERROR_403
    )
};

const onRejected = rejectionHandler([on403Reducer]);

const onPending: promisePattern.PromisePendingHandler =
    (state) => sprout.deepMerge<State>(state, {
        server: {
            account: {
                users: {
                    fetching: true,
                    error: null
                }
            }
        }
    });

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);
export interface TeamsUI {
    inviteTeamMemberOpen: boolean;
    inviteTeamMemberDialogOpen: boolean;
    teamMemberToUpdate?: string;
    confirmGenerateTokenOpen?: boolean;
}

export const InitialTeamsUiState = {
    inviteTeamMemberOpen: false,
    inviteTeamMemberDialogOpen: false,
    teamMemberToUpdate: ""
};
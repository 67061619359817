import {DocsServerState} from "./serverState/DocsServerState";
import {DocsUiState} from "./uiState/DocsUiState";
import {ScreenSize} from "../../src/state/uistate/LayoutUiState";

export interface DocsState {
    ui: DocsUiState;
    server: DocsServerState;
}

export const docsInitialState: DocsState = {
    ui: {
        layout: {
            screenSize: ScreenSize.LARGE
        }
    },
    server: {
        auth: {},
        contactUs: {
            contactCustomerSupport: {
                requestsFulfilled: 0,
                processing: false
            }
        },
        intercom: {},
        register: {},
        MFA: {},
        user: {
            email: "",
            payment: {
                createToken: {
                    processing: false,
                    error: ""
                }
            },
            subscription: {
                fetchCount: 0,
            },
            apiKeys: {
                processing: false,
                fetching: false,
                fetchCount: 0,
                lastCreatedKey: null,
                keys: [],
            }
        },
        account: {
            fetching: false,
            processing: false,
            accountInfo: {
                name: null,
                id: null,
                requireMfa: false
            },
            users: {
                fetching: false,
                processing: false,
                list: []
            },
            invites: {
                fetching: false,
                processing: false,
                invitees: [],
                lastInvited: ""
            }
        }
    }
};
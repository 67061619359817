import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {State} from "../../../state/State";
import {rejectionHandler} from "../../rejectionHandler";
import {deleteInvitedAccountUser} from "../../../communication/account";
import {
    DeleteInvitedUserRequest
} from "../../../communication/dtos/account/DeleteInvitedUser";

export const type = "deleteInvitedAccountUser";

export interface DeleteInvitedMemberActionResponse {
    userId: string;
}

export const actionCreator = promisePattern.actionCreator(type, (params: DeleteInvitedUserRequest) => {
    return deleteInvitedAccountUser(params).then(() => {
        return new Promise((resolve) => {
            resolve({userId: params.userId});
        });
    });
});

const onFulfilled: promisePattern.PromiseFulfilledHandler<DeleteInvitedMemberActionResponse> = (state, payload) => {
    const updatedInvitedMembers = state.server.account.invites.invitees.filter(p => p.userId !== payload?.userId);

    return sprout.assoc(state,
        ["server", "account", "invites", "invitees"], updatedInvitedMembers,
        ["server", "account", "invites", "processing"], false,
        ["server", "account", "error"], null
    );
};

const onRejected = rejectionHandler();

const onPending: promisePattern.PromisePendingHandler =
    (state) => sprout.deepMerge<State>(state, {
        server: {
            account: {
                invites: {
                    processing: true,
                    error: null
                }
            }
        }
    });

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);
import * as parseLinkHeader from "parse-link-header";
import * as urlUtils from "./urlUtils";
import * as mockProcessing from "./mocking/mockProcessing";
import {RestError} from "./errors/RestError";
import {Settings} from "./Settings";
import {QueryParamObject} from "./QueryParamObject";
import {promiseErrorHandler} from "../../util/promiseErrorHandler";
import {PaginatedRequestStatusCallback} from "./PaginatedRequestStatus";

/**
 * Settings for communication with the Giftbit server.
 */
export const settings: Settings = {
    root: "",
    mock: {
        handlers: []
    }
};

/**
 * Fetch from the giftbit API.
 * @param path the path that you wish to fetch, relative to the rest root
 * @param params query parameters for the request
 * @param init options object containing any custom settings that you want to apply to the request
 *             see: https://developer.mozilla.org/en-US/docs/Web/API/GlobalFetch/fetch
 * @returns a Promise that resolves to a Response object
 */
export const fetchRaw = (path: string, params?: QueryParamObject, init: RequestInit = {}): Promise<Response> => {
    if (typeof path !== "string") {
        return Promise.reject(new Error("'path' must be a string."));
    }

    if (path.length && path[0] !== "/") {
        path = "/" + path;
    }
    if (!init.method) {
        init.method = "GET";
    }

    init.credentials = "include";

    if (params) {
        path = urlUtils.appendQuery(path, params);
    }
    path = urlUtils.appendQuery(path, {_cachebust: urlUtils.generateId()});

    if (path.length > urlUtils.maxUrlLength) {
        return Promise.reject(new Error(`URL length is longer than the max URL length (${urlUtils.maxUrlLength}).`));
    }

    if (settings.mock) {
        const mockResponse = mockProcessing.processMock(settings.mock, path, init);
        if (mockResponse) {
            return mockResponse;
        }
    }

    const request = new Request(path, init);
    request.headers.append("X-Requested-With", "XMLHttpRequest");

    return fetch(request);
};

/**
 * Fetch and parse JSON from the giftbit API.
 * @param path the path that you wish to fetch, relative to the rest root
 * @param params query parameters for the request
 * @param init options object containing any custom settings that you want to apply to the request
 *             see: https://developer.mozilla.org/en-US/docs/Web/API/GlobalFetch/fetch
 * @returns a Promise that resolves to a Response object
 */
export const fetchJson = (path: string, params?: QueryParamObject, init?: RequestInit): Promise<any> =>
    fetchRaw(path, params, init)
        .then(response => {
            // 4xx and 5xx status codes indicate an error.  Otherwise it's a success.
            if (response.ok) {
                //204 NO CONTENT Response
                if (response.status === 204) {
                    return "";
                } else {
                    return response.json();
                }
            } else {
                return response.json()
                    .then(json => {
                        throw new RestError(response.status, json);
                    }, () => {
                        throw new RestError(response.status, {message: "", code: response.statusText});
                    });
            }
        })
        .catch(err => promiseErrorHandler(err));

/**
 * Fetch CSV from the API.
 * @param path the path that you wish to fetch, relative to the rest root
 * @param params query parameters for the request
 * @param init options object containing any custom settings that you want to apply to the request
 *             see: https://developer.mozilla.org/en-US/docs/Web/API/GlobalFetch/fetch
 * @returns a Promise that resolves to CSV text
 */
export const fetchCsv = (path: string, params?: QueryParamObject, init?: RequestInit): Promise<string> => {
    // if init has value, spread the existing into a new object with csv headers
    let csvInit: RequestInit;
    if (!!init) {
        csvInit = {...init, headers: {...(init && init.headers), "Accept": "text/csv"}};
    } else {
        csvInit = {headers: {"Accept": "text/csv"}};
    }

    return fetchRaw(path, params, csvInit)
        .then(response => {
            // 4xx and 5xx status codes indicate an error.  Otherwise it's a success.
            if (response.ok) {
                //204 NO CONTENT Response
                if (response.status === 204) {
                    return "";
                } else {
                    return response.text();
                }
            } else {
                return response.json()
                    .then(json => {
                        throw new RestError(response.status, json);
                    }, () => {
                        throw new RestError(response.status, {message: "", code: response.statusText});
                    });
            }
        })
        .catch((err) => promiseErrorHandler(err));
};

/**
 * Fetch paginated CSV from the API and stitch all the results together.
 * @param path the path that you wish to fetch, relative to the rest root
 * @param params query parameters for the request
 * @param init options object containing any custom settings that you want to apply to the request
 *             see: https://developer.mozilla.org/en-US/docs/Web/API/GlobalFetch/fetch
 * @param statusCallback optional callback with status of page being fetched
 * @returns a Promise that resolves to CSV text
 */
export const fetchPaginatedCsv = async (
    path: string,
    params?: QueryParamObject | null,
    init?: RequestInit | null,
    statusCallback?: PaginatedRequestStatusCallback
): Promise<string> => {
    // if init has value, spread the existing into a new object with csv headers
    let csvInit: RequestInit;
    if (init) {
        csvInit = {...init, headers: {...(init && init.headers), "Accept": "text/csv"}};
    } else {
        csvInit = {headers: {"Accept": "text/csv"}};
    }

    let hasMoreResults = true;
    let csvHeader = "";
    const results: string[] = [];
    try {
        while (hasMoreResults) {
            hasMoreResults = false;
            if (statusCallback) {
                statusCallback({pageCount: results.length});
            }

            const response = await fetchRaw(path, params, csvInit);
            if (!response.ok || response.status !== 200) {
                return response.json()
                    .then(json => {
                        throw new RestError(response.status, json);
                    }, () => {
                        throw new RestError(response.status, {message: "", code: response.statusText});
                    });
            }

            const responseText = await response.text();
            if (responseText) {
                if (!csvHeader) {
                    csvHeader = responseText.substring(0, responseText.indexOf("\n"));
                } else if (!responseText.startsWith(csvHeader)) {
                    throw new Error(`While downloading paginated CSV result header "${responseText.substring(0, responseText.indexOf("\n"))}" does not match the header of the first page "${csvHeader}" path=${path}`);
                }
                results.push(responseText.substring(csvHeader.length + 1));

                const linkHeader = response.headers.get("link");
                if (linkHeader) {
                    const links = parseLinkHeader(linkHeader);
                    if (links["next"]) {
                        hasMoreResults = true;
                        path = links["next"].url;
                        params = null;
                    }
                }
            }
        }

        return csvHeader + results.join("\n");
    } catch (err) {
        return promiseErrorHandler(err);
    }
};

export const NumbersOnlyRegExp = /^[0-9]*$/;
export const NumbersCommasDecimalsSpacesOnlyRegExp = /^[0-9,. ]*$/;
export const NumbersCommasSpacesOnlyRegExp = /^[0-9, ]+$/;
export const FloatOrNumbersOnlyRegExp = /^\d*\.?\d*$/;
export const FloatTwoDecimalLimitRegExp = /^\d*\.?(\d{1,2})?$/;
export const AlphaOnlyRegExp = /^[A-za-z.\s_-]*$/;
export const ContainsNonWhiteSpaceCharsRegEx = /\S/;
export const EmailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
export const UrlRegex = /^(https:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
export const AlphaSpacesRegex = /^[0-9A-Za-z ]*$/;
export const PROGRAM_METADATA_NOTES_KEY = "lightrail_webapp_program_notes";
export const BALANCE_RULE_PERCENT_REGEX = /^currentLineItem\.lineTotal\.subtotal\s\*\s(?!0+(?:\.0+)?$)\d?\d(?:\.\d\d?)?$/;
export const BALANCE_RULE_AMOUNT_REGEX = /^\d+\s\+\svalue\.balanceChange$/;

type OnMatchCB = () => void;

/**
 * @param {string} mediaQuery - media query to match against, ex: "(min-width:400px)"
 * @param {()=>void} onMatch - function to call when the media query matches
 */
export const onMatchMedia = (mediaQuery: string, onMatch: OnMatchCB): void => {
    const matchMedia = window.matchMedia(mediaQuery);
    if (matchMedia.matches) {
        onMatch();
    }

    matchMedia.addListener(mql => {
        if (mql.matches) {
            onMatch();
        }
    });
};
import * as promisePattern from "../../promisePattern";
import * as auth from "../../../communication/auth";
import * as sprout from "sprout-data";
import {deepMerge} from "sprout-data";
import {ResetPasswordResponse} from "../../../communication/dtos/auth/ResetPassword";
import {rejectionHandler} from "../../rejectionHandler";
import {onPasswordRejected} from "./passwordRejectionHandler";
import {UserModes} from "../../../communication/dtos/auth/PingResponse";
import {setSentryUserContext} from "../../../util/sentry";
import {initialState} from "../../../state/State";
import {redirectOnMessageCode} from "../../../util/routes/redirectOnMessageCode";

export const type = "resetPassword";

export const actionCreator = promisePattern.actionCreator(type, auth.resetPassword);

const onFulfilled: promisePattern.PromiseFulfilledHandler<ResetPasswordResponse> =
    (state, payload) => {
        if (!!payload?.user) {
            const testing = payload?.user.mode === UserModes.TEST;
            if (payload?.user.email) {
                setSentryUserContext(payload?.user.email);
            }

            const accountMessage = {
                message: payload?.message,
                messageCode: payload?.messageCode
            };

            const user = sprout.assoc(state.server.user,
                ["email"], payload?.user.email,
                ["id"], payload?.user.id,
                ["testing"], testing,
                ["loggedIn"], true,
                ["additionalAuthenticationRequired"], payload?.user.additionalAuthenticationRequired
            );

            return sprout.assoc(initialState,
                ["server", "auth", "processing"], false,
                ["server", "MFA", "enabled"], !!payload?.user.mfa,
                ["server", "user"], user,
                ["server", "account", "authMessage"], accountMessage,
                ["ui", "history"], redirectOnMessageCode(state.ui.history, accountMessage.messageCode)
            );
        } else {
            return sprout.assoc(state, ["server", "auth", "resetPasswordMessage"], payload?.message);
        }
    };

const onRejected: promisePattern.PromiseRejectedHandler = rejectionHandler([onPasswordRejected]);

const onPending: promisePattern.PromisePendingHandler =
    (state) => {
        return deepMerge(state, {
            server: {
                auth: {
                    processing: true,
                    errorMessage: []
                }
            }
        });
    };

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);
import * as promisePattern from "../../promisePattern";
import * as auth from "../../../communication/auth";
import * as sprout from "sprout-data";
import { rejectionHandler, RejectionReducer } from "../../rejectionHandler";
import { RegisterResponse } from "../../../communication/dtos/auth/Register";

export const type = "register";

export const actionCreator = promisePattern.actionCreator(type, auth.register);

const onFulfilled: promisePattern.PromiseFulfilledHandler<RegisterResponse> =
    (state) => {
        return sprout.assoc(state,
            ["server", "register", "processing"], false,
            ["server", "register", "emailSent"], true
        );
    };

export const onRegisterRejected: RejectionReducer = {
    reducer: (state: any, error: any) => {
        let errorArray: string[];
        if (typeof error.message === "string") {
            errorArray = [error.message];
        } else {
            errorArray = error.message as string[];
        }
        return sprout.assoc(state,
            ["server", "register", "processing"], false,
            ["server", "register", "error"], errorArray
        );
    },
    overrideGlobal: true
};

const onRejected: promisePattern.PromiseRejectedHandler = rejectionHandler([onRegisterRejected]);

const onPending: promisePattern.PromisePendingHandler =
    (state) => {
        return sprout.assoc(state,
            ["server", "register", "processing"], true,
            ["server", "register", "error"], []
        );
    };

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);
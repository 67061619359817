import * as sprout from "sprout-data";
import * as promisePattern from "../../promisePattern";
import {State} from "../../../state/State";
import {rejectionHandler, RejectionReducer} from "../../rejectionHandler";
import {createApiKey} from "../../../communication/userApiKeys";
import {CreateApiKeyResponse} from "../../../communication/dtos/user/apiKeys/CreateApiKey";

export const type = "createApiKeys";

export const actionCreator = promisePattern.actionCreator(type, createApiKey);

const onFulfilled: promisePattern.PromiseFulfilledHandler<CreateApiKeyResponse> =
    (state, payload) => sprout.assoc(state,
        ["server", "user", "apiKeys", "lastCreatedKey"], payload,
        ["server", "user", "apiKeys", "keys"], [...state.server.user.apiKeys.keys, payload],
        ["server", "user", "apiKeys", "processing"], false,
        ["server", "user", "error"], null
    );

const onCreateRejected: RejectionReducer = {
    reducer: (state) => sprout.assoc(state, ["server", "user", "apiKeys", "processing"], false)
};

const onRejected = rejectionHandler([onCreateRejected]);

const onPending: promisePattern.PromisePendingHandler =
    (state) => sprout.deepMerge<State>(state, {
        server: {
            user: {
                apiKeys: {
                    processing: true,
                    error: null
                }
            }
        }
    });

export const reducerMap = promisePattern.reducerMap(type, onFulfilled, onRejected, onPending);